(function($, document, window, lq) {
	
	lq.footer = (function(){
		function getFooter(){
			var lang = lq.support.lang,
				servicesURI = lq.configs.srvDomain + '/loteries-endpoints/' + lang + '/pied-de-page',
				options = {
                'type': 'GET',
                'url': servicesURI,
                'dataType': 'html',
                'cache': true,
                'success': function (data) {
                	if ($.trim(data) == undefined || $.trim(data) == null || $.trim(data) == "" ){
						console.log('displayreeor from error:');
                	} else {
                		$("footer").html(data);
                		$("#piedPageLienLangue").attr('href', lq.configs.lienAutreLangue);
                	}
				},
                'error': function (xhr, status, errMsg) {
                    // Si en dev, output un message d'erreurs, les paramètres et les headers reçus.
                    if (lq.support.isDev) {
                        //lq.console.log(['Update footer error', arguments, xhr.getAllResponseHeaders()]);
                    	console.log(['Update footer error', arguments, xhr.getAllResponseHeaders()]);
                    }
                    //lq.console.log('displayreeor from error:');
                    console.log('displayreeor from error:');
                }
            };
			$.ajax(options);
		}
		return{
        	initResult: function(){
				getFooter();
			}
        };
    })();
    // Update global scope lq namespace
    window.lq = lq;

    //--> Auto trigger all self implemented functionalities on document ready
    lq.load.push(
        "lq.footer", [
            'initResult'
        ]
    );

}($, document, window, (window.lq || {})));