(function($, document, window, lq) {

    //Suite de tests pour le décodage des pdf417. À utiliser au besoin.
    /*test("", "");
    test(" ", "");
    test("P223003000003009RE5K2ANR5Z3", "P223003000003009RE5K2ANR");
    test("P2230*0300'0-3009R5K_2AN5Z3", "");
    test("A104000025000ZGF6JFGSZ45", "A104000025000ZGF6JFGS");
    test("32705569066946339316900874683703C1C209794*LOTOQUEBEC*S8EMUPRT1234", "27055690669463393");
    test("3660556059721310063532087468D1B0391373321*LOTOQUEBEC*S8EMUPRT1234", "66055605972131006");
    test("36605563434165870223540874680079E98DB8528*LOTOQUEBEC*S8EMUPRT1234", "66055634341658702");
    test("52A3627UZZHYYISFFAHYKCAKN7D14087468DF67F1ABCC9B0*LOTOQUEBEC*S8EMUPRT1234", "2A3627UZZHYYISFFAHYKCAKN");
    test("3620556010142148069144087468F930391A78037*LOTOQUEBEC*S8EMUPRT1234", "62055601014214806");
    test("320055613559326884943908746888D78F956F484*LOTOQUEBEC*S8EMUPRT1234", "20055613559326884");
    test("33205563017073450266850874686EC0375DFABC5*LOTOQUEBEC*S8EMUPRT1234", "32055630170734502");
    test("3640557741753437466618087468DA169A6AB46F2*LOTOQUEBEC*S8EMUPRT1234", "64055774175343746");
    test("44660556077977812539246087468661002580000122009621002582", "");
    test("322055633181341234556008746810FFE95E89C46*LOTOQUEBEC*S8EMUPRT1234", "22055633181341234");
    test("320055613559326F956F486*LOBEC*S8T1234", "");
    test("52A3627UZZHYYIS67F1ABCC9B6*LOTOBEC*S8EMT1234", "");*/

    lq.pdf417 = (function() {
        return {
            test: function(input, expected) {
                let actual = getNoControleFromPDF417(input);
                console.log("+ case: " + input);
                console.log("+ got : " + actual);
                console.log("+ res : " + (expected == actual) + "\n");
            },
        
            getNoControleFromPDF417: function(rawData) {
                let controlNumber = "";
        
                if (rawData !== "") {
        
                    controlNumber = getJEFTicketCtrlNumber(rawData);
        
                    // Check for several number format.
                    if (controlNumber == "") {
                        controlNumber = getPassiveOrInstantTicketCtrlNumber(rawData);
                    }
        
                    if (controlNumber == "") {
                        controlNumber = getFSGTicketCtrlNumber(rawData);
                    }
                }
        
                return controlNumber;
            },
        
        }
})();

function test(input, expected) {
    let actual = getNoControleFromPDF417(input);
    console.log("+ case: " + input);
    console.log("+ got : " + actual);
    console.log("+ res : " + (expected == actual) + "\n");
}

function getNoControleFromPDF417(rawData) {
    let controlNumber = "";

    if (rawData !== "") {

        controlNumber = getJEFTicketCtrlNumber(rawData);

        // Check for several number format.
        if (controlNumber == "") {
            controlNumber = getPassiveOrInstantTicketCtrlNumber(rawData);
        }

        if (controlNumber == "") {
            controlNumber = getFSGTicketCtrlNumber(rawData);
        }
    }

    return controlNumber;
}

function getJEFTicketCtrlNumber(rawData) {
    const RAW_DATA_LENGTH = 65
    let controlNumber = "";

    if (rawData.startsWith("3") && rawData.length == RAW_DATA_LENGTH) {
         controlNumber = rawData.substring(1, 18);
    }

    return controlNumber;
}

function getFSGTicketCtrlNumber(rawData) {
    const RAW_DATA_LENGTH = 72;
    let controlNumber = "";

    if (rawData.startsWith("5") && rawData.length == RAW_DATA_LENGTH) {
            controlNumber = rawData.substring(1, 25);
    }

    return controlNumber;
}

function getPassiveOrInstantTicketCtrlNumber(rawData) {
    const PASSIVE_LENGTH_WITHOUT_MAC = 24;
    const INSTANTANEE_LENGTH_WITHOUT_MAC = 21;

    let controlNumber = "";

    if (isPassiveFormatDetected(rawData)) {
        controlNumber = rawData.substring(0, PASSIVE_LENGTH_WITHOUT_MAC);
    } else if (isInstantFormatDetected(rawData)) {
        controlNumber = rawData.substring(0, INSTANTANEE_LENGTH_WITHOUT_MAC);
    }

    return controlNumber;
}

function isPassiveFormatDetected(rawData) {
    const LENGTH_WITH_MAC = 27;

    if (rawData.length == LENGTH_WITH_MAC) {
        return isControlNumberPassive(rawData);
    } else {
        return false;
    }
}

function isInstantFormatDetected(rawData) {
    const LENGTH_WITH_MAC = 24;

    if (rawData.length == LENGTH_WITH_MAC) {
        return isControlNumberInstant(rawData);
    } else {
        return false;
    }
}

function isControlNumberPassive(rawData) {
    const LENGTH_WITHOUT_MAC = 24;
    const regex = /^[P-X](?:\d{6}){2}\d{3}(?:[A-Z2-7]{4}){2}$/i;

    let stripInputStr = extractOnlyAlphaNumString(rawData);

    const useLength = Math.min(stripInputStr.length, LENGTH_WITHOUT_MAC);

    stripInputStr = stripInputStr.substring(0, useLength);

    return regex.test(stripInputStr);
}

function isControlNumberInstant(rawData) {
    const LENGTH_WITHOUT_MAC = 21;
    const regex = /^[A-I]\d{3}\d{6}\d{3}(?:[A-Z2-7]{4}){2}$/i;

    let stripInputStr = extractOnlyAlphaNumString(rawData);

    const useLength = Math.min(stripInputStr.length, LENGTH_WITHOUT_MAC);

    stripInputStr = stripInputStr.substring(0, useLength);

    return regex.test(stripInputStr);
}

function extractOnlyAlphaNumString(rawData) {
    const regex = /\W/i;
    let trimStr = rawData.replace(regex, "");

    // \\W include the underscore '_' so we remove it manually.
    trimStr = trimStr.replace("_", "");

    return trimStr;
}

// Update global scope lq namespace
window.lq = lq
    // Auto trigger all self implemented (plugin) functionalities
})(jQuery, document, window, window.lq || {})