/* eslint-disable no-param-reassign */

import jQuery from 'jquery';
import cookies from 'browser-cookies';
import { isMobile, isAndroid, isIOS } from 'mobile-device-detect';

const CTX_PATH_MGN_AUTHOR = '/magnoliaAuthor/';

function obtenirUrlCookieCrossDomain(currentLocation) {

    let urlAutreDomaine = currentLocation;
    const loteriesLotoquebec = '.lotoquebec';
    const loteriesEspacejeux = '.espacejeux';

    if (urlAutreDomaine.indexOf(loteriesLotoquebec) !== -1) {
        urlAutreDomaine = urlAutreDomaine.replace(
            loteriesLotoquebec,
            loteriesEspacejeux,
        );
    } else {
        urlAutreDomaine = urlAutreDomaine.replace(
            loteriesEspacejeux,
            loteriesLotoquebec,
        );
    }
    return urlAutreDomaine;
}

function closeCatfish(nbJourExpirationCatfish) {

    const domaineActuel = window.location.hostname;
    const autreDomaine = obtenirUrlCookieCrossDomain(domaineActuel);

    let html;

    if (window.location.pathname.indexOf('magnoliaAuthor/') >= 0) {
        cookies.set('catfishFermer', 'true', {
            path: '/',
            expires: nbJourExpirationCatfish,
        });
    } else {
        html = '';
        html = `${html}<img src="https://${domaineActuel}/loteries-endpoints/cookie-cross-domain?domaine=${domaineActuel}&nom=catfishFermer&valeur=true&duree=${nbJourExpirationCatfish * 24 * 60 * 60}" />`;
        html = `${html}<img src="https://${autreDomaine}/loteries-endpoints/cookie-cross-domain?domaine=${autreDomaine}&nom=catfishFermer&valeur=true&duree=${nbJourExpirationCatfish * 24 * 60 * 60}" />`;

        jQuery('#cookieAutreDomaine').show();
        jQuery('#cookieAutreDomaine').html(html);
    }

    jQuery('#lqCatfish').hide();
    jQuery('#lqCatfishMobile').hide();
}

function catfishGestionCookie(nbJourExpirationCatfish) {
    let domaineActuel = '';
    let autreDomaine = '';
    let html = '';

    jQuery('#catfishClose').click(() => {
        closeCatfish(nbJourExpirationCatfish);
    });

    jQuery('#catfishMobileClose').click(() => {
        closeCatfish(nbJourExpirationCatfish);
    });

    jQuery('#catfishUrl').click(() => {
        closeCatfish(nbJourExpirationCatfish);
    });

    jQuery('#catfishMobileUrl').click(() => {
        closeCatfish(nbJourExpirationCatfish);
    });

    jQuery('#cookieAutreDomaine').click(() => {
        closeCatfish(nbJourExpirationCatfish);
    });

    domaineActuel = window.location.hostname;
    autreDomaine = obtenirUrlCookieCrossDomain(domaineActuel);

    if (window.location.pathname.indexOf('magnoliaAuthor/') >= 0) {
        cookies.set('catfishAfficher', window.location.pathname, {
            path: '/',
            expires: nbJourExpirationCatfish,
        });
    } else {
        html = '';
        html = `${html}<img src="https://${domaineActuel}/loteries-endpoints/cookie-cross-domain?domaine=${domaineActuel}&nom=catfishAfficher&valeur=${window.location.pathname}&duree=${nbJourExpirationCatfish * 24 * 60 * 60}" />`;
        html = `${html}<img src="https://${autreDomaine}/loteries-endpoints/cookie-cross-domain?domaine=${autreDomaine}&nom=catfishAfficher&valeur=${window.location.pathname}&duree=${nbJourExpirationCatfish * 24 * 60 * 60}" />`;

        jQuery('#cookieAutreDomaine').show();
        jQuery('#cookieAutreDomaine').html(html);
    }
}

function traiterUrl(url) {
    const lowerCaseURL = url.toLowerCase();
    const isRelative = !lowerCaseURL.indexOf('http') > -1;
    let outUrl = url;

    if (isRelative && window.location.href.indexOf(CTX_PATH_MGN_AUTHOR) > -1) {
        outUrl = `${lq.configs.hostMgn5}${url}`;
    }

    return outUrl;
}

function populateDOM(catfish, prefixeImgURL, paramLangue) {
    let srcsetStringImgLarge = '';
    const imgLargeKeys = Object.keys(catfish.imgLarge);
    for (let i = 0; i < imgLargeKeys.length; i++) {
        srcsetStringImgLarge += catfish.imgLarge[imgLargeKeys[i]];
        const splitKey = imgLargeKeys[i].split('-');
        srcsetStringImgLarge
            += splitKey[1] !== undefined ? ` ${splitKey[1]},` : '';
    }
    srcsetStringImgLarge += srcsetStringImgLarge.substr(
        0,
        srcsetStringImgLarge.length,
    );

    let srcsetStringImgPetite = '';
    const imgPetiteKeys = Object.keys(catfish.imgPetite);
    for (let i = 0; i < imgPetiteKeys.length; i++) {
        srcsetStringImgPetite += catfish.imgPetite[imgPetiteKeys[i]];
        const splitKey = imgPetiteKeys[i].split('-');
        srcsetStringImgPetite
            += splitKey[1] !== undefined ? ` ${splitKey[1]},` : '';
    }
    srcsetStringImgPetite += srcsetStringImgPetite.substr(
        0,
        srcsetStringImgPetite.length,
    );

    jQuery('#lqCatfish').css(
        'background-color',
        catfish.couleurFond !== undefined ? catfish.couleurFond : '',
    );
    jQuery('#lqCatfishMobile').css(
        'background-color',
        catfish.couleurFond !== undefined ? catfish.couleurFond : '',
    );

    catfish.imgLarge !== undefined &&
        jQuery('#catfishImage').attr('src', catfish.imgLarge.default);

    catfish.imgLarge !== undefined &&
        jQuery('#catfishImage').attr('srcset', srcsetStringImgLarge);

    catfish.texteAlternatifImgLarge !== undefined &&
        jQuery('#catfishImage').attr('alt', catfish.texteAlternatifImgLarge);

    catfish.lienDesktop !== undefined &&
        jQuery('#catfishUrl').attr('href', traiterUrl(catfish.lienDesktop));

    catfish.etiquetteAnalytiques !== undefined &&
        jQuery('#catfishUrl').attr('analytics', catfish.etiquetteAnalytiques);

    catfish.imgPetite !== undefined &&
        jQuery('#catfishMobileImage img').attr('src', catfish.imgPetite.default);

    catfish.imgPetite !== undefined &&
        jQuery('#catfishMobileImage').attr('srcset', srcsetStringImgPetite);

    catfish.texteAlternatifImgPetite &&
        jQuery('#catfishMobileImage').attr(
            'alt',
            catfish.texteAlternatifImgPetite,
        );

    catfish.etiquetteAnalytiques !== undefined &&
        jQuery('#catfishMobileUrl').attr(
            'analytics',
            catfish.etiquetteAnalytiques,
        );

    if (catfish.field === 'oui') {
        if (isIOS) {
            catfish.lienIOS !== undefined &&
                jQuery('#catfishMobileUrl').attr(
                    'href',
                    traiterUrl(catfish.lienIOS),
                );
        } else if (isAndroid) {
            catfish.lienAndroid !== undefined &&
                jQuery('#catfishMobileUrl').attr(
                    'href',
                    traiterUrl(catfish.lienAndroid),
                );
        }
    }

    if (catfish.field === 'non') {
        catfish.lienAutre !== undefined &&
            jQuery('#catfishMobileUrl').attr(
                'href',
                traiterUrl(catfish.lienAutre),
            );
    }
}

export default function loadCatfish(
    catfishContent,
    prefixeImgURL,
    paramLangue,
) {
    const ID = '#lqCatfish';
    const ID_MOBILE = '#lqCatfishMobile';
    const AFFICHER = 'catfishAfficher';
    const FERMER = 'catfishFermer';
    const COOKIE = 'withCookie';
    const NO_COOKIE = 'noCookie';

    if (cookies.get(FERMER) !== 'true' && (cookies.get(AFFICHER) === null || window.location.pathname === cookies.get(AFFICHER))) {
        jQuery(ID).addClass(NO_COOKIE);
        jQuery(ID_MOBILE).addClass(NO_COOKIE);

        populateDOM(catfishContent, prefixeImgURL);
        catfishGestionCookie(+catfishContent.nombreDeJours);
    } else {
        jQuery(ID).addClass(COOKIE);
        jQuery(ID_MOBILE).addClass(COOKIE);
    }
}

(function($, document, window, lq) {
    const paramLangue = `${lq.support.lang}-CA`;




    const prefixeURL = `${lq.configs.pathAssets}/webresources/images/catfish/`;

    lq.catfish = (function() {
        async function getCatfishArrayFromEndpoint() {
            const getContentFromEndpoint = () => new Promise((res, rej) => {
                $.getJSON(
                    `${lq.configs.internalSrvDomain}/.rest/delivery/catfish/@nodes?lang=${paramLangue}`,
                )
                    .done((data) => res(data))
                    .fail((jqxhr, textStatus, error) => {
                        const err = `${textStatus}, ${error}`;
                        console.error(
                            `Catfish -- Problème pendant l'obtention du contenu provenant de l'endpoint, erreur reçu : ${err}`,
                        );
                    });
            });

            const catfishArr = await getContentFromEndpoint();

            return catfishArr !== undefined ? catfishArr : [];
        }

        async function filterCatfishEndpointArray(catfishArray) {
            if (catfishArray === undefined || catfishArray.length === 0) {
                return null;
            }

            const now = await lq.commun.getDate();

            const catfishContentArr = catfishArray.filter((node) => {
                const timestamDebut = Date.parse(node.dateDebut);
                const dateDebut = new Date(timestamDebut);

                const timestampFin = node.dateFin === undefined ? undefined : Date.parse(node.dateFin);
                const dateFin = timestampFin === undefined ? undefined : new Date(timestampFin);

                if (now > dateDebut && dateFin !== undefined && now < dateFin) {
                    return true;
                }

                if (now > dateDebut && dateFin === undefined) {
                    return true;
                }

                return false;

            });

            // Pour le moment on retourne le premier résultat ou on retourne null si on trouve pas d'élement
            return catfishContentArr.length > 0 ? catfishContentArr[0] : null;

        }

        function transformerUrlImagesAuBesoin(catfishContent) {
            const imgLarge = {};
            const imgPetite = {};
            const REGEX_REMPLACEMENT = /\/magnoliaPublic/;
            const CTX_PATH_REMPLACEMENT = lq.configs.internalSrvDomain;

            if (catfishContent.imgLarge !== undefined
                && catfishContent.imgLarge.renditions !== undefined
                && catfishContent.imgLarge.metadata.mimeType.indexOf('svg') === -1) {
                if (prefixeURL.indexOf(CTX_PATH_MGN_AUTHOR) > -1) {
                    const keys = Object.keys(catfishContent.imgLarge.renditions);
                    for (let i = 0; i < keys.length; i++) {
                        imgLarge[keys[i]] = catfishContent.imgLarge !== undefined
                            && catfishContent.imgLarge.renditions[keys[i]].link;
                    }
                    imgLarge.default = catfishContent.imgLarge['@link'];
                } else {
                    const keys = Object.keys(catfishContent.imgLarge.renditions);
                    for (let i = 0; i < keys.length; i++) {
                        imgLarge[keys[i]] = catfishContent.imgLarge !== undefined
                            && catfishContent.imgLarge.renditions[
                                keys[i]
                            ].link.replace(
                                REGEX_REMPLACEMENT,
                                CTX_PATH_REMPLACEMENT,
                            );
                    }
                    imgLarge.default = catfishContent.imgLarge['@link'].replace(
                        REGEX_REMPLACEMENT,
                        CTX_PATH_REMPLACEMENT,
                    );
                }
            }

            if (catfishContent.imgLarge !== undefined
                && catfishContent.imgLarge.metadata.mimeType.indexOf('svg') > -1) {
                if (prefixeURL.indexOf(CTX_PATH_MGN_AUTHOR) > -1) {
                    imgLarge.default = catfishContent.imgLarge['@link'];
                } else {
                    imgLarge.default = catfishContent.imgLarge['@link'].replace(
                        REGEX_REMPLACEMENT,
                        CTX_PATH_REMPLACEMENT,
                    );
                }
            }

            if (catfishContent.imgPetite !== undefined
                && catfishContent.imgPetite.renditions !== undefined
                && catfishContent.imgPetite.metadata.mimeType.indexOf('svg') === -1) {
                if (prefixeURL.indexOf(CTX_PATH_MGN_AUTHOR) > -1) {
                    const keys = Object.keys(catfishContent.imgPetite.renditions);
                    for (let i = 0; i < keys.length; i++) {
                        imgPetite[keys[i]] = catfishContent.imgPetite !== undefined
                            && catfishContent.imgPetite.renditions[keys[i]].link;
                    }
                    imgPetite.default = catfishContent.imgPetite['@link'];
                } else {
                    const keys = Object.keys(catfishContent.imgPetite.renditions);
                    for (let i = 0; i < keys.length; i++) {
                        imgPetite[keys[i]] = catfishContent.imgPetite !== undefined
                            && catfishContent.imgPetite.renditions[
                                keys[i]
                            ].link.replace(
                                REGEX_REMPLACEMENT,
                                CTX_PATH_REMPLACEMENT,
                            );
                    }
                    imgPetite.default = catfishContent.imgPetite['@link'].replace(
                        REGEX_REMPLACEMENT,
                        CTX_PATH_REMPLACEMENT,
                    );
                }
            }

            if (catfishContent.imgPetite !== undefined
                && catfishContent.imgPetite.metadata.mimeType.indexOf('svg') > -1) {
                if (prefixeURL.indexOf(CTX_PATH_MGN_AUTHOR) > -1) {
                    imgPetite.default = catfishContent.imgPetite['@link'];
                } else {
                    imgPetite.default = catfishContent.imgPetite['@link'].replace(
                        REGEX_REMPLACEMENT,
                        CTX_PATH_REMPLACEMENT,
                    );
                }
            }

            const result = {...catfishContent, imgLarge, imgPetite };

            return result;
        }

        return {
            init: (async() => {
                try {
                    if (
                        jQuery('#lqCatfish').length === 0 &&
                        jQuery('#lqCatfishMobile').length === 0
                    ) {
                        return false;
                    }

                    const catfishArray = await getCatfishArrayFromEndpoint();

                    const content = await filterCatfishEndpointArray(
                        catfishArray,
                    );

                    const contentModifie = (content !== null && transformerUrlImagesAuBesoin(content)) || null;

                    contentModifie !== null &&
                        loadCatfish(contentModifie, prefixeURL, paramLangue);

                    return true;
                } catch (err) {
                    console.error(
                        `Erreur pendant le démarrage du catfish : ${err}`,
                    );
                    return false;
                }
            })(),
        };
    }());

    window.lq = lq;

    lq.load.push('lq.catfish', ['init']);
}(jQuery, document, window, window.lq || {}));