/*global jQuery,lq,console*/
/*jshint -W030*/
+(function ($, document, window, lq) {
  'use strict';

  /**
   * Create or extend i18n sub-namespace of lq (Loto Quebec) namespace
   * @requires lq.support
   **/
  lq.i18n = $.extend((lq.i18n || {}), {
    fr: {
      monthsFull: ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'],
      monthsShort: ['jan', 'fév', 'mar', 'avr', 'mai', 'jun', 'jul', 'aoû', 'sep', 'oct', 'nov', 'déc'],
      weekdaysShort: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
      labelMonthNext: 'Mois suivant',
      labelMonthPrev: 'Mois précédent',
      playPause: 'Démarer/arrêter',
      lastUpdate: 'Dernière mise à jour : ',
      socialShare: 'Partager sur les médias sociaux',
      socialShareFacebook: 'Partager sur Facebook',
      socialShareTwitter: 'Partager sur Twitter',
      groslot1CHXlabel: 'Main crève-cœur',
      groslot1MTLlabel: 'Main crève-cœur',
      groslot1LLlabel: 'Main crève-cœur'

    },
    en: {
      monthsFull: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      monthsShort: ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'],
      weekdaysShort: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
      labelMonthNext: 'Go to the next month',
      labelMonthPrev: 'Go to the previous month',
      playPause: 'Play/Pause',
      lastUpdate: 'Last update: ',
      socialShare: 'Share on social medias',
      socialShareFacebook: 'Share on Facebook',
      socialShareTwitter: 'Share on Twitter',
      groslot1CHXlabel: 'Bad Beat Jackpot',
      groslot1MTLlabel: 'Bad Beat Jackpot',
      groslot1LLlabel: 'Bad Beat Jackpot'
    },
    
    //Formatter une date à partir du format yyyy-MM-ddThh:mm:ss
	obtenirDateDuString : function (sDate) {
		var str = sDate.split('T'),
			date = str[0].split('-'),
			time = str[1].split(':');
		
		return new Date(date[0], date[1]-1, date[2], time[0], time[1], time[2], 0);
	},
    
    obtenirDateHeureFormatee : function (date) {
		var langue = lq.support.lang,
			moisFr = ['JANVIER', 'FÉVRIER', 'MARS', 'AVRIL', 'MAI', 'JUIN', 'JUILLET', 'AOÛT', 'SEPTEMBRE', 'OCTOBRE', 'NOVEMBRE', 'DÉCEMBRE'],
			moisEn = ['JANUARY', 'FEBRUARY', 'MARCH', 'APRIL', 'MAY', 'JUNE', 'JULY', 'AUGUST', 'SEPTEMBER', 'OCTOBER', 'NOVEMBER', 'DECEMBER'],
			heures = date.getHours(),
			minutes = date.getMinutes(),
			minutesFormatee = minutes < 10 ? '0' + minutes : minutes,
			abreviation  = heures < 12 ? 'a.m.' : 'p.m.',
			expo = date.getDate() == 1 ? 'er' : '',
			chaineExpo = expo != '' ? expo.sup() : '',
			dateHeureFormatee = date;

		if(langue === 'en' ) {
			dateHeureFormatee = moisEn[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear() + ', ' + (heures % 12 || 12) + ':' + minutesFormatee + ' ' + abreviation;
		} else {
			dateHeureFormatee = date.getDate() + chaineExpo + ' ' + moisFr[date.getMonth()] + ' ' + date.getFullYear() + ', ' + heures + '\xa0h\xa0' + minutesFormatee;
		}

		return dateHeureFormatee;
	},
	
	formaterEnArgent : function (montant, nbDec, separateur, separateurDec) {
		var nbDec = isNaN(nbDec = Math.abs(nbDec)) ? 2 : nbDec,
		separateurDec = separateurDec == undefined ? "." : separateurDec,
		separateur = separateur == undefined ? "," : separateur,
		i = parseInt(montant = Math.abs(+montant || 0).toFixed(nbDec)) + "",
		j = (j = i.length) > 3 ? j % 3 : 0;

		return (j ? i.substr(0, j) + separateur : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + separateur) + (nbDec ? separateurDec + Math.abs(montant - i).toFixed(nbDec).slice(2) : "");
	},
	
	obtenirMontantFormate : function (montant, decPlaces, thouSeparator, decSeparator) {
		var langue = lq.support.lang,
			montantFormate = montant;

		if(langue === 'en' ) {
			montantFormate = '$' + lq.i18n.formaterEnArgent(montant, 0, ',', '.');
		} else {
			montantFormate = lq.i18n.formaterEnArgent(montant, 0, ' ', ',') + ' $';
		}

		return montantFormate;
	},

    get: function (key) {
      return (key in lq.i18n[lq.support.lang]) ? lq.i18n[lq.support.lang][key] : key;
    }
  });

  // Update global scope lq namespace
  window.lq = lq;

}(jQuery, document, window, (window.lq || {})));
