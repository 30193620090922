(function ($, document, window, lq) {
    lq.utils = (function () {
        return {
            getDataValue: function ($el, prop, defaultValue) {
                var test = $el.data(prop);
                return typeof test === "undefined" ? defaultValue : test;
            },

            /**
             * Build a unique ID.
             * @param {String} [prefix=''] Optional string to prefix the generated id
             * @returns {String}.
             * @function uId
             * @memberOf lq.utils
             */
            uId: function (prefix) {
                prefix = prefix || '';
                return prefix + (Math.round((+new Date()) + (Math.random() * 1000)));
            }
        };
    })();

    window.lq = lq;
    //--> Auto trigger all self implemented functionalities on document ready
    lq.load.push(
        "lq.utils", []
    );

}($, document, window, (window.lq || {})));
