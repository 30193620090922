/*global jQuery,lq,console*/
/*jshint -W030*/
+(function ($, document, window, lq) {
	// 'use strict';

	var $html = $('html'),
		$W = $(window),
		ieV = parseFloat((((/MSIE ([0-9][\.0-9]*)/).exec(navigator.userAgent)) || ((/rv:([0-9][\.0-9]*)\) like Gecko$/).exec(navigator.userAgent)) || [0, 0])[1]),
		// jQuery Show Hide method will be extends to add and remove aria-hidden attribute
		jQueryOldShow = $.fn.show,
		jQueryOldHide = $.fn.hide;;


	lq.support = $.extend((lq.support || {}), {
		//--> Properties
		lang: $html.prop('lang') || 'fr',
		ieVersion: ieV,
		isIe: (ieV > 0),
		isIeLt8: (ieV > 0 && ieV < 8) || $html.is(".lt-ie8"),
		isIeLt9: (ieV > 0 && ieV < 9) || $html.is(".lt-ie9"),
		isIeLt10: (ieV > 0 && ieV < 10) || $html.is(".lt-ie10"),
		isIeLt11: (ieV > 0 && ieV < 11)
	});

	/**
     * Add class to identify if current browser is Ie
     */
	initIEBrowserIdentification: (function () {
		//lq.console.log('Initialisation of IE Browser identification...');
		(lq.support.isIe)
			? $('html').addClass('is-ie')
			: null;
	})(),

		// Console support setting and detection

		// Helper for IE console indentation
		lq.support.ieLogIndent = "";

	lq.support.isDev = $html.data('console-verbose') || (location.hostname === 'localhost') || false; //(location.port !== '8080' && location.port !== '80' && location.port !== "") || false;
	lq.support.console = (typeof console !== 'undefined' && lq.support.isDev);
	lq.support.consoleLog = lq.support.console && !!console.log;
	lq.support.consoleWarn = lq.support.console && !!console.warn;
	lq.support.consoleTime = lq.support.console && !!console.time;
	lq.support.consoleGroup = lq.support.console && !!console.group;

	// Dummy elements to test browser support and speed up element creation
	lq.support.dummyDiv = document.createElement('div');
	lq.support.dummyImg = document.createElement('img');

	// Defined animation end event name for callback, base on browser
	lq.support.animationEndEventName = (function () {
		var trans = {
			'animation': 'animationend',
			'OAnimation': 'oAnimationEnd',
			'MozAnimation': 'animationend',
			'WebkitAnimation': 'webkitAnimationEnd',
			'msAnimation': 'MSAnimationEnd'
		},
			tr;
		// loop and test for style properties. On first match, use this name.
		for (tr in trans) {
			if (trans.hasOwnProperty(tr)) {
				if (lq.support.dummyDiv.style[tr] !== undefined) {
					return trans[tr];
				}
			}
		}
		// no match found, use default
		return trans.animation;

	}());

	// screen resolution display
	lq.support.showScreenResolution = (function () {
		if (lq.support.isDev) {
			var $div = $(lq.support.dummyDiv).clone();

			$div.addClass('screen-resolution-display');

			$div.appendTo('body');

			var updateValue = function () {
				$div.html('screen: ' + screen.width + ' x ' + screen.height + '<br>vp: ' + window.innerWidth + ' x ' + window.innerHeight);
			};

			$W.on('load resize orientationchange', function () {
				updateValue();
			});

			return true;
		}
		return false;
	})();


	/**
	 * Search a method or property from a string representation of it in a scope. Ex.: "lq.utils.pad" will find a sub-namespace 'utils' inside the namespace 'lq' and search for a property or method with the name 'pad' to return.
	 * This is very useful, for example, to call a method in a namespace from a string callback of an Ajax call.
	 * @param {string} sNs String to evaluate.
	 * @param {object} [oBase=window] Optional - Object/namespace for the search. Default = window.
	 * @returns {*} Method or property if sNs is properly evaluate in the oBase, otherwise, false. If sNs is not a string, sNs is return.
	 */
	lq.support.parseNameSpace = function (sNs, oBase) {
		oBase = oBase || window;
		if (typeof sNs === 'string') {
			var parts = sNs.split('.');
			if (parts.length > 1 && typeof oBase[parts[0]] === 'object') {
				// Recursively call parseNameSpace
				return lq.support.parseNameSpace(parts.splice(1).join('.'), oBase[parts[0]]);
			} else {
				return oBase[parts[0]] || false;
			}
		}
		return sNs;
	};

	lq.support.device = (navigator.userAgent.toLowerCase()).match(/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera/i);

	lq.support.iOSversion = function () {
		if (/iP(hone|od|ad)/.test(navigator.platform)) {
			// supports iOS 2.0 and later: <http://bit.ly/TJjs1V>
			var v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
			return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
		}
		return [0];
	};

	lq.support.isIOS = function () {
		return lq.support.iOSversion.length > 0;
	};

	// Update global scope lq namespace
	window.lq = lq;

	/***
	 * Remove console log/warn styling arguments for IE - Not supported
	 * @returns {Array} Received arguments filtered
	 */
	lq.support.ieConsoleArgumentsFilter = function () {
		var args = Array.prototype.slice.call(arguments);
		// If first element start with %c, second elements is console styling for this output. Remove styling and %c in the first element
		//console.log(typeof args[0]);
		if (args.length > 1 && (typeof args[0] == 'string') && args[0].indexOf('%c') === 0) {
			args.splice(1, 1);
			args[0] = args[0].substr(2);
		}
		// Return filtered arguments array.
		return args;
	};

	// Add support of indexOf on array, when not natively supported (basicaly ie < 9 and firefox < 1.5
	if (lq.support.isIeLt9 || !Array.prototype.indexOf) {
		Array.prototype.indexOf = function (searchElement, fromIndex) {
			if (this === undefined || this === null) {
				throw new TypeError('"this" is null or not defined');
			}

			var length = this.length >>> 0; // Hack to convert object.length to a UInt32

			fromIndex = +fromIndex || 0;

			if (Math.abs(fromIndex) === Infinity) {
				fromIndex = 0;
			}

			if (fromIndex < 0) {
				fromIndex += length;
				if (fromIndex < 0) {
					fromIndex = 0;
				}
			}

			for (; fromIndex < length; fromIndex++) {
				if (this[fromIndex] === searchElement) {
					return fromIndex;
				}
			}

			return -1;
		};
	}
	// Fix ie <9 splice function - Check if it is IE and it's version is 8 or older
	if (lq.support.isIeLt9) {

		(function () {
			// save original function of splice
			var originalSplice = Array.prototype.splice;

			// provide a new implementation
			Array.prototype.splice = function () {

				// since we can't modify 'arguments' array,
				// let's create a new one and copy all elements of 'arguments' into it
				var arr = [],
					i = 0,
					max = arguments.length;

				for (; i < max; i++) {
					arr.push(arguments[i]);
				}

				// if this function had only one argument
				// compute 'deleteCount' and push it into arr
				if (arr.length == 1) {
					arr.push(this.length - arr[0]);
				}

				// invoke original splice() with our new arguments array
				return originalSplice.apply(this, arr);
			};
		})();

	}

	// Add support of replaceAll on String object
	if (!String.prototype.replaceAll) {
		/**
		 * Find a value by string or regexp and replace all instance by a specific string
		 * @param {String|RegExp} find String or regexp to match
		 * @param {String} replace String to replace all matches
		 * @returns {string} String where "find" have bean replace by "replace"
		 */
		String.prototype.replaceAll = function (find, replace) {
			if (this === undefined || this === null) {
				throw new TypeError('"this" is null or not defined');
			}
			// Support for regular expression
			if (find instanceof RegExp) {
				// Ensure "global" flag is set to replace all occurrence
				if (!find.global) {
					find = new RegExp(find.source, "g" + (find.ignoreCase ? "i" : "") + (find.multiline ? "m" : ""));
				}
				return this.replace(find, replace);
			}
			return this.split(find).join(replace);
		};
	}

	// Add support for trim on String object (IE < 9)
	if (typeof String.prototype.trim !== 'function') {
		String.prototype.trim = function () {
			return this.replace(/^\s+|\s+$/g, '');
		};
	}

	// Extend jQuery for support :focusable selector
	$.extend($.expr[':'], {
		focusable: function (el) {
			return $(el).is('a, button, :input, [tabindex]');
		}
	});

	lq.support.safe = function (selector, defaultVal) {
		var $el;
		defaultVal = (defaultVal === undefined ? $('') : defaultVal);
		try {
			$el = $(selector);
		} catch (error) {
			return defaultVal;
		}
		return ($el.length ? $el : defaultVal);
	};

	// Extend jQuery fn to add relative position parent and aria-hidden control on show and hide methods
	/**
	 * Extend jQuery fn
	 * - Add positionedParent() to get the firs positioned parent
	 * - Change show() Add auto support of aria-hidden attribute
	 * - Change hide() Add auto support of aria-hidden attribute
	 */
	$.fn.extend({
		positionedParent: function () {
			// if there is no element
			if (!this[0]) {
				return this;
			}
			var elem = this[0],
				$closestPositionedParent;

			// Fixed position element are relative to window
			if ($.css(elem, 'position') === 'fixed') {
				$closestPositionedParent = $W;
			} else {
				// parents().filter() will not stop on first positioned parent, so use a each loop
				this.parents().each(function () {
					// Reduce to only positioned elements or "html" as default element
					if ($.nodeName(this, 'html') || $.css(this, 'position') !== 'static') {
						$closestPositionedParent = $(this);
						// Positioned element have been found or root have been reached. Break the each loop!
						return false;
					}
				});
			}

			return $closestPositionedParent;
		},
		show: function (speed, easing, callback) {
			// original behavior - use function.apply to preserve context
			var $ret = jQueryOldShow.apply(this, arguments);

			// Remove aria-hidden attribute
			$ret.each(function () {
				$(this).removeAttr('aria-hidden').removeClass('aria-hidden');
			});

			return $ret;
		},
		hide: function (speed, easing, callback) {
			// original behavior - use function.apply to preserve context
			var $ret = jQueryOldHide.apply(this, arguments);

			// set aria-hidden attribute to true on all elements
			$ret.each(function () {
				$(this).attr('aria-hidden', true).addClass('aria-hidden');
			});

			return $ret;
		}
	});
}(jQuery, document, window, (window.lq || {})));
