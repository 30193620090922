(function ($, document, window, lq) {

    lq.admin = (function () {
        function remplacerLienRelatif() {
            var liens = document.querySelectorAll('[data-lien-m5]'), i;

            if (liens.length != 0) {
                const CONTEXT = '/magnoliaAuthor';
                const CONTEXT_LEN = CONTEXT.length;

                var uri = liens[0].href;
                var index = uri.indexOf(CONTEXT) + CONTEXT_LEN;

                for (i = 0; i < liens.length; i++) {
                	if(liens[i].href.indexOf(CONTEXT) != -1){
                		uri = liens[i].href.substring(index);
                	}else{
                		uri = liens[i].href;
                	}
                    liens[i].href = lq.configs.hostMgn5 + uri;
                }
            }
        }
        return {
            initResult: function () {
                //Aucun lien relatif ne devrait etre change si nous sommes en public
                if (window.location.pathname.indexOf('/magnoliaAuthor') >= 0) {
                    remplacerLienRelatif();
                }
            }
        };
    })();
    window.lq = lq;

    //--> Auto trigger all self implemented functionalities on document ready
    lq.load.push(
        "lq.admin", [
        'initResult'
    ]
    );

}($, document, window, (window.lq || {})));
