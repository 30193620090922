/*! LQ NameSpace | Copyright 2015 Loto Quebec Inc. */
/*global jQuery,lq,console*/
/*jshint -W030*/
import { Html5Qrcode, Html5QrcodeSupportedFormats } from "html5-qrcode";

(function($, document, window, lq) {
    'use strict';
    /**
     * Conteneur-vbel 
     **/
    //console.log("Ça marche vbel!");

    let regexPFE = /^[1-9]/mi;
    let regexInstant = /^[A-I]/mi;
    let regexPassive = /^[P-X]/mi;
    let regexInvalide = /^[^A-IP-X1-9]/mi;
    let regexAlpha = /[a-zA-Z]/mi;
    let regexAlphanumerique = /^[^a-zA-Z0-9]/mi;
    let regexFormuleSuperGroupe = /^[a-zA-Z2-7]/mi;
    let regexFormuleSuperGroupeSansTiret  = /^[A-Z2-7]{4}(?:[A-Z2-7]{4}){5}/mi;
    let regexJefCaractereSpecialInvalide = /[^a-zA-Z0-9]/mi;
    let regexCommence0 = /^[0]/mi;
    let regexPFEComplete = /^[1-9]\d(?:-\d{4}){3}-\d{3}([a-zA-Z0-9]-[a-zA-Z0-9]{3})?$/mi;
    let regexInstantComplete = /^[a-iA-I]-\d{3}-\d{6}-\d{3}(?:-[a-zA-Z2-7]{4}){2}(-[a-zA-Z0-9]{3})?$/mi;
    let regexPassiveComplete = /^[p-xP-X](?:-\d{6}){2}-\d{3}(?:-[a-zA-Z2-7]{4}){2}(-[a-zA-Z0-9]{3})?$/mi;
    let regexFormuleSuperGroupeComplete = /^[a-zA-Z2-7]{4}(-[a-zA-Z2-7]{4}){5}(-[a-zA-Z0-9]{4})$/mi;
    let regexValidationFSG = /[^-a-zA-Z2-7]/mi;
    let regexValidationFSGMac = /[^-a-fA-F0-9]/mi;
    let regexFSGInvalide = /[0189]/mi;
    let regexFSGMacInvalide = /[g-zG-Z]/mi;
    var masqueActif = false;
    var erreurCodeInvalide = false;
    var erreurCodeJefInvalide = false;
    var erreurValidation = false;
    var erreurCommence0 = false;
    var erreurIncomplet = false;
    var erreurIndE9999 = false;
    var codeJEF = false;
    var codePassif = false;
    var codeInstant = false;
    var codeFormuleSuperGroupe = false;
    var scanCamera = false;
    var scanPdf417 = false;
    var scanDataRecu = false;
    var key = "";
    var longueur = "";
    var premierCaractere = "";
    var premierCaractereInitial = "";
    var valeurRadioBouton = "";
    var iCircle = String.fromCharCode(9432);
    var nbHyphen = String.fromCharCode(8209);
    var urlBase = window.location.href;
    var currentURL = window.location.href;
    var statutBanniere = "";
    var payloadReclamation = "";
    var startReclamationURL = lq.configs.creationReclamation;
    var isMobile = require("is-mobile");
    
    if(lq.support.lang == "en") {
        var inscriptionURL = lq.configs.url2eChanceEn;
        var reclamationURL = lq.configs.urlReclamationEn;
    } else {
        var inscriptionURL = lq.configs.url2eChance;
        var reclamationURL = lq.configs.urlReclamation; 	
    }
        
    if(!isMobile({tablet: true, featureDetect: true})) {
        $("#vbelTab").hide();
        $("#verifCamera").removeClass("show active");
        $("#verifManuelle").addClass("show active");
        $("#aide_etape1").show();
    } else {
        $("#aide_etape1Camera").show();
        $("#aide_etape1").hide();
    }

    var nTimer = setTimeout(function() {
        // correction du problème de lqcat-rows-dynamique	
        //$("div.container-fluid").removeClass("container-fluid");
        var billet = "";
        var titre = "";
        var infoLib = "#info";

        $(".conteneur-vbel-zone-publicite").find(".media-card-image").removeClass("mb-3");

        if ($("#ignoreetape1").text() == 'false') {
            history.replaceState("etape1", "", "");
        }

        window.onpopstate = function(event) {
            if (history.state == "etape1") {
                goBack();
                currentURL = window.location.href;
            }
            if (history.state.indexOf("etape2") !== -1) {
                goBackEtape2();
                currentURL = window.location.href;
            }
        }

        // $("#zoneInfoEt1 #icircle1").text(iCircle);
        if ($(infoLib).text().length > 0) {
            $("#zoneInfoEt1 p").html($(infoLib).html());
            $("#zoneInfoEt1").removeClass("hide").addClass("d-flex");
        } else {
            $("#zoneInfoEt1").removeClass("d-flex").addClass("hide");
        }
        
        function resetEncadreFSG() {
        	$("#lienFSG").hide();
        	$("#lienFSGAVenir").hide();
        	$("#lienFSG").empty();
        	$("#lienFSGAVenir").empty();
        }
        
        function resetEncadre2eChance() {
        	//console.log("on reset 2e chance");
        	$("#message2eChance").empty();
        	$("#encadre2eChance").hide();
        	$("#formParticiper").show();
        }
        
        function resetEncadreReclamation() {
        	$("#lienReclamation").removeClass("flex");
        }

        function resetModal() {
            $("#contenuModalCamera").empty();
            $(".encadreVbel").css("background-image", "");
            resetEncadreFSG();
            resetEncadreReclamation();
            resetEncadre2eChance();
        }
        
        function goEtape2() {
            //console.log("go etape 2");
            if ($('input[name="billet"]:checked').length > 0) {
                billet = $('input[name="billet"]:checked').val();
                if (billet == "superGroupe") {
                    codeFormuleSuperGroupe = true;
                }
                infoLib = "#info" + billet + "2";
                titre = "#titre" + billet;
                $("#etape1").addClass("hide");
                $("#etape2").removeClass("hide");
                $("#aide_etape2_" + billet).show();
                $("#aide_etape1").hide();
                $("#aide_etape1Camera").hide();
                $("#publicite_etape1").hide();
                $("#publicite_etape2").show();
                billet = $('input[name="billet"]:checked').val();
                infoLib = "#info" + billet + "2";
                titre = "#titre" + billet;
                if ($(infoLib).text().length > 0) {
                    $("#zoneInfoEt2 p").html($(infoLib).html());
                    $("#zoneInfoEt2").removeClass("hide").addClass("d-flex");
                } else {
                    $("#zoneInfoEt2").removeClass("d-flex").addClass("hide");
                }
                if ($(titre).text().length > 0) {
                    $("#etape2 p.h3").text($(titre).text());
                }
                if ($("#ignoreetape1").text() == 'true') {
                    history.replaceState("etape2_superGroupe", "", "");
                } else {
                    history.pushState("etape2_" + billet, "", "");
                }
                currentURL = window.location.href;
            } else {
                $("#etape1 #erreurValidation").removeClass("hideErreurValidation");
            }
        }

        if ($("#ignoreetape1").text() == 'true') {
            $("#superGroupe").attr('checked', 'checked');
            goEtape2();
        }

        $("#boutonGoEtape2").on("click", function(e) {
            goEtape2();
        });

        $("#verifCamera-tab").on("click", function(e) { 
            $("#aide_etape1Camera").show();
            $("#aide_etape1").hide();
        }); 
        
        $("#verifManuelle-tab").on("click", function(e) { 
            $("#aide_etape1").show();
            $("#aide_etape1Camera").hide();
        }); 
        
        $("#formParticiper").submit(function(e){
        	if(inscriptionURL != "" && inscriptionURL != null && inscriptionURL != undefined) {
        		$("#formParticiper").attr("action", inscriptionURL);
                $("#noControleParticiper").attr("value", $("#lqNumeroControle").val());
                
                if(statutBanniere == 4) {
                    $("#noControleParticiper").attr("value", "");
        			$("#formParticiper").attr("action", inscriptionURL.replace("_verifier", ""));
                }
                
                $("#origine").attr("value", window.location.pathname + window.location.hash);
    		} else {
    			e.preventDefault();
    		}
        });

        //Ici sera la fonction de submit pour la réclamation. Changer inscriptionUrl pour la bonne url. On appelle pas 2e chance.
        //Il faut mettre l'url de l'application de réclamation avec le numéro de controle
        $("#formReclamation").submit(function(e){

            if(typeof window.loginNav !== "undefined" && window.loginNav !== null && typeof window.loginNav.isLoggedIn !== "undefined") {
                var isLoggedIn = window.loginNav.isLoggedIn;
            } else {
                var isLoggedIn = false;
            }
            
            if(lq.support.lang == "en") {
                if(isLoggedIn) {                  
                    var reclamationURL = lq.configs.urlReclamationEJEn;

                } else {
                    var reclamationURL = lq.configs.urlReclamationEn;
                }
            } else {
                if(isLoggedIn) {
                    var reclamationURL = lq.configs.urlReclamationEJ;
                } else {
                    var reclamationURL = lq.configs.urlReclamation;
                }	
            }

        	if(reclamationURL != "" && reclamationURL != null && reclamationURL != undefined) {
                var options = {
                    'type': 'POST',
                    'url': startReclamationURL,
                    'data': '{"reclamation" : "' + payloadReclamation + '"}',
                    'dataType': "json",
                    'contentType' : "application/json",
                    'cache': true,
                    'async': false,
                    'success': function(data) {
                        $("#payloadReclamation").attr("value", data.payloadReclamation);
                        $("#formReclamation").attr("action", reclamationURL);
                    },
                    'error': function(xhr, status, errMsg) {
                        // S'il y a erreur, on appelle REL sans parametres pour avoir la page d'erreur.
                        $("#formReclamation").attr("action", reclamationURL);
                    }
                };
                $.ajax(options);      		
    		} else {
    			e.preventDefault();
    		}
        });


        //pb du masque qui garde pas le focus 
        $("#lqZoneNumeroControle").on("click", function() {
            $(".masque-superpose").css("z-index", "-1");
            $('#lqNumeroControle').focus();
            $(".masque-superpose").css("z-index", "0");
        });

        function goBack() {
            //console.log("go back etape 1");
            if ($("#ignoreetape1").text() == 'true') {
                $("#etape1").addClass("hide");
                $("#etape2").removeClass("hide");
                $("#etape3").addClass("hide");
                $("#aide_etape1").hide();
                $("#aide_etape1Camera").hide();
                $("#aide_etape2_superGroupe").show();
                $("#aide_etape3").hide();
                $("#publicite_etape1").hide();
                $("#publicite_etape2").show();
                $("#publicite_etape3").hide();
            } else {
                $("#etape1").removeClass("hide");
                $("#etape2").addClass("hide");
                $("#etape3").addClass("hide");
                if($("#verifCamera").hasClass("active")) {
                    $("#aide_etape1Camera").show();
                } else {
                    $("#aide_etape1").show();
                }
                $("#aide_etape3").hide();
                $('[id^="aide_etape2_"]').css('display', 'none');
                $('[id^="aide_etape2Camera"]').css('display', 'none');
                $("#publicite_etape1").show();
                $("#publicite_etape2").hide();
                $("#publicite_etape3").hide();
                codeFormuleSuperGroupe = false;
                scanCamera = false;
                $("#iconeBack").off("click.stopCamera");
                $(".fermerCamera").off("click.resumeCamera");
            }
            $("#vbelTab").removeClass("hide");
            $("#selectionCamera").removeClass("hide");
            $("#etapeCamera").addClass("hide");
            $(".encadreVbel").css("background-image", "");
            $("#lqNumeroControle").val("");
            $("#nomProduit").empty();
            $("#message").empty();
            $("#noControle").empty();
            $("#logoVbelProduit").removeAttr("src");
            $("#logoVbelProduit").css("display", "none");
            //$("#logoVbelProduit").css("display","block");
            resetEncadreFSG();
            resetEncadreReclamation();
            resetEncadre2eChance();
            resetMasque();
        }

        $(".goBack").on("click", function(e) {
            history.back();
            goBack();
        });

        $(".goBack3").on("click", function(e) {
            if ($("#ignoreetape1").text() == 'false') {
                history.pushState("etape1", "", "");
            }
            else {
            	history.pushState("etape2_superGroupe", "", "");
            }
            goBack();
        });

        function goBackEtape2() {
            $("#etape1").addClass("hide");
            $("#etape2").removeClass("hide");
            $("#etape3").addClass("hide");
            $("#vbelTab").removeClass("hide");
            $("#aide_etape1").hide();
            $("#aide_etape1Camera").hide();
            $("#aide_etape3").hide();
            $("#aide_etape2" + history.state.split("etape2").pop()).show();
            $("#publicite_etape1").hide();
            $("#publicite_etape2").show();
            $("#publicite_etape3").hide();
            $("#nomProduit").empty();
            $("#message").empty();
            $("#noControle").empty();
            $("#logoVbelProduit").removeAttr("src");
            $('#popupSelection').modal('hide'); 
            $(".modal-backdrop").remove();
            $("body").removeClass("modal-open");
            resetEncadreFSG();
            resetEncadreReclamation();
            resetEncadre2eChance();
        }

        function goScanCamera() {
            $("#vbelTab").addClass("hide");
            $("#selectionCamera").addClass("hide");
            $("#etapeCamera").removeClass("hide");
            history.pushState("etape2Camera", "", "");
            $("#aide_etape1").hide();
            $("#aide_etape1Camera").hide();
            $("#aide_etape3").hide();
            $("#iconeBack").attr("src", lq.configs.pathAssets + "/webresources/images/svg/btn_retour.svg");
            $("#iconeAide").attr("src", lq.configs.pathAssets + "/webresources/images/svg/btn_aide.svg");
            $("#iconeFermer").attr("src", lq.configs.pathAssets + "/webresources/images/svg/btn_fermer.svg");
        }

        function getValidationVbel() {
            var lang = lq.support.lang,
            //Clés dupliquées du fichier properties
	            i18n = {
	                    fr: {
	                    	nbMaximalParts: 'Nombre <strong>maximal</strong> de parts : ',
	                    	nbPartsVendues: 'Nombre de parts <strong>vendues</strong> : ',
	                    	lienSelections: 'Consulter la liste des s\u00E9lections',
	                    	deuxiemeChanceGagnant: 'Ce n’est pas fini! Vous pourriez encore gagner un lot en participant à <em>2<sup>e</sup>&nbsp;chance</em>!',
	                    	deuxiemeChancePerdant: 'Tout n’est pas perdu. Vous pourriez quand même gagner un lot en participant à <em>2<sup>e</sup>&nbsp;chance</em>!',	
	                    	deuxiemeChanceGenerique: 'Participez à <em>2<sup>e</sup>&nbsp;chance</em> avec ce billet pour avoir une deuxième chance de gagner un lot!',
	                    	deuxiemeChanceErreur: 'Il n’est pas possible de vérifier si ce billet est admissible à <em>2<sup>e</sup>&nbsp;chance</em> pour le moment.',
	                    	deuxiemeChanceBadPortion: 'N’oubliez pas de vous inscrire à l’aide de la portion <em>2<sup>e</sup>&nbsp;chance</em> de votre billet. À gagner : des participations télé supplémentaires et plus encore!',
	                    	deuxiemeChanceCelebration: 'N’oubliez pas de vous inscrire à l’aide de la portion <em>2<sup>e</sup>&nbsp;chance</em> de votre billet.',
	                    	boutonBanniere: 'Participer',
	                    	boutonBanniere2eChance: 'Aller \u00E0 l\u2019inscription',
                            boutonReclamation: 'RÉCLAMER MON LOT',
                            reclamationTitre: '<strong>BONNE NOUVELLE!</strong>',
                            reclamationTexte: 'Vous pouvez réclamer ce lot en ligne dès maintenant.',
	                    },
	                    en: {
	                    	nbMaximalParts: '<strong>Maximum</strong> number of shares: ',
		                    nbPartsVendues: 'Number of shares <strong>sold</strong>: ',
		                    lienSelections: 'See the list of selections',
		                    deuxiemeChanceGagnant: 'It’s not over yet! You could still win another prize by entering <em>2nd&nbsp;chance</em>!',
		                    deuxiemeChancePerdant: 'All is not lost. You could still win a prize by entering <em>2nd&nbsp;chance</em>!',	
		                    deuxiemeChanceGenerique: 'Enter <em>2nd&nbsp;chance</em> with this ticket for a second chance to win a prize!',
	                    	deuxiemeChanceErreur: 'It’s not possible to check if this ticket is eligible for <em>2nd&nbsp;chance</em> at this time.',
	                    	deuxiemeChanceBadPortion: 'Remember to enter the <em>2<sup>e</sup>&nbsp;chance</em> portion of your ticket. Up for grabs: Bonus entries for the televised draw and more!',
	                    	deuxiemeChanceCelebration: 'Remember to enter the <em>2nd&nbsp;chance</em> portion of your ticket.',
	                    	boutonBanniere: 'Enter now',
	                    	boutonBanniere2eChance: 'Enter it now',
                            boutonReclamation: '<strong>CLAIM YOUR PRIZE</strong>',
                            reclamationTitre: 'GOOD NEWS!',
                            reclamationTexte: 'You can claim your prize online now.',
	                    },
	
	                    get: function(key) {
	                        return (key in i18n[lang]) ? i18n[lang][key] : key;
	                    }
	                };
            var noControle = $("#lqNumeroControle").val(),
                PLATEFORME_LOTERIES = "portail",
                APP_LOTERIES = "LEL",
                prefixeURL = lq.configs.pathAssets + '/webresources/images/';
                if(scanCamera && scanPdf417) {
                    var MODE_SAISIE_LOTERIES = "PDF";
                } else if (scanCamera && !scanPdf417) {
                    var MODE_SAISIE_LOTERIES = "QR";
                } else {
                    var MODE_SAISIE_LOTERIES = "manuelle";
                }
                var servicesURI = lq.configs.svcBilletsVerif + '?noControle=' + noControle + '&modeSaisie=' + MODE_SAISIE_LOTERIES + '&plateforme=' + PLATEFORME_LOTERIES + '&app=' + APP_LOTERIES + '&lang=' + lang;
            var options = {
                    'type': 'GET',
                    'url': servicesURI,
                    'dataType': 'html',
                    'xhrFields': { withCredentials: false },
                    'cache': true,
                    'success': function(data) {
                        //console.log('DATA DU ENDPOINT VBEL : ' + data);
                        //console.log('numero de controle' + noControle);
                        var reponse = JSON.parse(data);
                        if (reponse.status == "LOTE9999") {
                            $("#erreurSaisie").removeClass("hideErreurSaisie");
                            $("#erreurValidation").addClass("hideErreurValidation");
                            erreurIndE9999 = true;
                        } else {
                            
                            $("#nomProduit").html(reponse.nomProduit);
                            $("#message").html(reponse.message.replaceAll("\n", "<br>"));
                            var imgsrc = "";
                            var pIdMobile = reponse.idMobile;
                            var pNomProduit = reponse.nomProduit;

                            if (!!pNomProduit && pIdMobile != undefined && pIdMobile != null && pIdMobile != "" && pIdMobile != "INEXISTANT") {
                                if (pIdMobile == "miseojeu") {
                                    $("#logoVbelProduit").attr("src", lq.configs.pathAssets + "/webresources/images/svg/miseojeu.svg");
                                    $("#logoVbelProduit").css("display", "block");
                                } else if (pIdMobile == "PassiveInstant") {
                                    $("#logoVbelProduit").attr("src", lq.configs.pathAssets + "/webresources/images/svg/instantanegenerique.svg");
                                    $("#logoVbelProduit").css("display", "block");
                                }else {
                                    imgsrc = lq.commun.getProductVBEL(pIdMobile).then(
                                        (productVBEL) => {
                                        	if(!!productVBEL.logoVbelProduit && !!productVBEL.idMobile){
	                                            $("#logoVbelProduit").attr("src", "/portail-loteries/dam/" + productVBEL.logoVbelProduit + "/logo.png");
	                                            $("#logoVbelProduit").on("load", function () {$("#logoVbelProduit").css("display", "block");});
	                                            $("#logoVbelProduit").error(function(){ $("#logoVbelProduit").css("display", "none");});
                                            }
                                            return productVBEL.logoVbelProduit;
                                        }
                                    );
                                }
                            } else if(!pNomProduit) {
                                $("#logoVbelProduit").attr("src", lq.configs.pathAssets + "/webresources/images/svg/img_erreur_gris.svg");
                                $("#logoVbelProduit").css("display", "block");
                            }


                            if (!codeFormuleSuperGroupe && noControle.match(regexPFE)) {
                                $("#noControle").html(noControle + "*-***");
                            }
                            if (!codeFormuleSuperGroupe && (noControle.match(regexInstant) || noControle.match(regexPassive))) {
                                $("#noControle").html(noControle + "-***");
                            }
                            if (codeFormuleSuperGroupe && noControle.match(regexFormuleSuperGroupe)) {
                                $("#noControle").html(noControle + "-****");
                            }
                            $("#noControle").html($("#noControle").html().replaceAll('-', nbHyphen));
                            //On verifie si le gain n'est pas vide et qu'il existe pour afficher les confettis.
                            if (reponse.gagnant != "" && reponse.gagnant) {
                                $(".encadreVbel").css('background-image', 'url(' + prefixeURL + 'bkg_confettis.png)');
                            }
                            if(!scanCamera) {
                                history.pushState("etape3", "", "");
                                currentURL = window.location.href;
                                $("#etape2").addClass("hide");
                                $("#vbelTab").addClass("hide");
                                $("#etape3").removeClass("hide");
                                $("#aide_etape3").show();
                                $('[id^="aide_etape2_"]').css('display', 'none');
                                $("#publicite_etape3").show();
                                $("#publicite_etape2").hide();
                            }
                            
                            if(codeFormuleSuperGroupe && reponse.code != "E9999"){
                            	var blocSelections = '<div class="FSGFlex"><img id="logoFSGSelections"></img><div class="encadreFSG"><p id="nomProduitSelections"></p><p>' + i18n.get("nbMaximalParts") + '<span id="nbMaximalParts"></span></p><p>' + i18n.get("nbPartsVendues") + '<span id="nbPartsVendues"></span></p><a id="lienSelections" class="btn btn-primary" data-toggle="modal" data-target="#popupSelection">' + i18n.get("lienSelections") + '</a></div></div>';
                                var noControleTrim = reponse.billet.noControle.replaceAll("-", "");
                            	var urlSelection = lq.configs.domaineTransac;   
                            	//Cas : Selections disponibles
                                if(reponse.groupe.selectionsVisible && reponse.code != "V1017"){ 
                                	$("#lienFSG").html(blocSelections);
                                	//TODO RETIRER AVANT PRE-PROD
                                    if(lq.support.isDev){
                                    	//$("#lienFSG").html("<a id='lienSelections' href='https://loteries.dev-80.espacejeux.org/lel/" + lang + "/consulter-groupe-gdg?idGroupe=" + noControleTrim + "'>Selections</a>" );
                                    	var lienSelections = "https://loteries.dev-80.espacejeux.org/lel/" + lang + "/consulter-groupe-gdg?idGroupe=" + noControleTrim;
                                    	$("#lienSelections").attr("href", lienSelections);
                                    } else {
                                    	//$("#lienFSG").html("<a id='lienSelections' href='" + urlSelection + "/lel/" + lang + "/consulter-groupe-gdg?idGroupe=" + noControleTrim + "'>Selections</a>" );
                                    	var lienSelections = urlSelection + "/lel/" + lang + "/consulter-groupe-gdg?idGroupe=" + noControleTrim ;
                                    	$("#lienSelections").attr("href", lienSelections);
                                    }
                                    //$("#logoFSGSelections")
                                    $("#logoFSGSelections").attr("src", lq.configs.pathAssets + "/webresources/images/svg/logo_formulesupergroupe_reg.svg");
                                    $("#nomProduitSelections").html(reponse.nomProduit);
                                    //console.log(reponse.nombrePartsMax);
                                    $("#nbMaximalParts").html(reponse.groupe.nombrePartsMax);
                                    //console.log(reponse.nombrePartsVendues);
                                    $("#nbPartsVendues").html(reponse.groupe.nombrePartsVendues);
                                    $("#lienFSG").show();
                            		var ifr = document.getElementById("iframeFSG");
                            		ifr.contentWindow.location.replace(lienSelections); 
                                }
                                //Cas : Résultats à venir
                                if(reponse.groupe.selectionsVisible && reponse.code == "V1017"){
                                	$("#lienFSGAVenir").html(blocSelections);
                                    //console.log("url Selections : " + urlSelection);
                                    if(lq.support.isDev){
                                    	//$("#lienFSG").html("<a id='lienSelections' href='https://loteries.dev-80.espacejeux.org/lel/" + lang + "/consulter-groupe-gdg?idGroupe=" + noControleTrim + "'>Selections</a>" );
                                    	var lienSelections = "https://loteries.dev-80.espacejeux.org/lel/" + lang + "/consulter-groupe-gdg?idGroupe=" + noControleTrim;
                                    	$("#lienSelections").attr("href", lienSelections);
                                    } else {
                                    	//$("#lienFSG").html("<a id='lienSelections' href='" + urlSelection + "/lel/" + lang + "/consulter-groupe-gdg?idGroupe=" + noControleTrim + "'>Selections</a>" );
                                    	var lienSelections = urlSelection + "/lel/" + lang + "/consulter-groupe-gdg?idGroupe=" + noControleTrim ;
                                    	$("#lienSelections").attr("href", lienSelections);
                                    }
                                    $("#logoFSGSelections").attr("src", lq.configs.pathAssets + "/webresources/images/svg/logo_formulesupergroupe_reg.svg");
                                    $("#nomProduitSelections").html(reponse.nomProduit);
                                    //console.log(reponse.nombrePartsMax);
                                    $("#nbMaximalParts").html(reponse.groupe.nombrePartsMax);
                                    //console.log(reponse.nombrePartsVendues);
                                    $("#nbPartsVendues").html(reponse.groupe.nombrePartsVendues);
                                	$("#lienFSGAVenir").show();
                            		var ifr = document.getElementById("iframeFSG");
                            		ifr.contentWindow.location.replace(lienSelections); 
                                }
                            }

                            if(reponse.promotion.billetAdmissible == true) {

                                let message = "";
                                let libelleBoutonBanniere = i18n.get("boutonBanniere");
                            	let cheminLogo = "/webresources/images/svg/logo-2chance.svg";

                            	if(reponse.promotion.statutBanniere == '2') {
                            		//console.log("ADMISSIBLE et GAGNANT");
                            		message = i18n.get("deuxiemeChanceGagnant");

                            	} else if (reponse.promotion.statutBanniere == '3') {
                            		//console.log("ADMISSIBLE et PERDANT");
                            		message = i18n.get("deuxiemeChancePerdant");

                            	} else if(reponse.promotion.statutBanniere == '1') {
                            		//console.log("ADMISSIBLE et GÉNÉRIQUE");
                            		message = i18n.get("deuxiemeChanceGenerique");

                            	} else if(reponse.promotion.statutBanniere == '4') {
                            		//console.log("ADMISSIBLE et GÉNÉRIQUE");
                            		message = i18n.get("deuxiemeChanceBadPortion");
                            		libelleBoutonBanniere = i18n.get("boutonBanniere2eChance");

                            	} else if(reponse.promotion.statutBanniere == '5') {
                            		//console.log("ADMISSIBLE et GÉNÉRIQUE");
                            		message = i18n.get("deuxiemeChanceCelebration");
                                                              		
                            	} else if(reponse.promotion.statutBanniere == '99') {
                            		//console.log("ERREUR");
                            		message = i18n.get("deuxiemeChanceErreur");                              		
                            	}

                                $("#message2eChance").html(message);
                                $("#boutonParticiper").attr("value", libelleBoutonBanniere);
                                $("#logo2eChance").attr("src", lq.configs.pathAssets + cheminLogo);
                                $("#encadre2eChance").show();
                                statutBanniere = reponse.promotion.statutBanniere;

                            } else if(reponse.promotion.billetAdmissible == false) {
                            	if(reponse.promotion.statutBanniere == '99') {
                            		//console.log("ERREUR");
                            		$("#message2eChance").html(i18n.get("deuxiemeChanceErreur"));
                                	$("#encadre2eChance").show();   
                                	$("#logo2eChance").attr("src", lq.configs.pathAssets + "/webresources/images/svg/logo-2chance.svg");
                                	$("#formParticiper").hide();
                            	}
                            }

                            //Billet réclamable
                            if(reponse.reclamation.billetReclamable == true) {
                                $("#titreReclamation").html(i18n.get("reclamationTitre"));
                                $("#texteReclamation").html(i18n.get("reclamationTexte"));
                                $("#produitReclamation").attr("value", reponse.nomProduit);
                                //$("#descriptionPrixReclamation").attr("value", reponse.reclamation.descriptionPrix);
                                //$("#reclamation").attr("value", reponse.reclamation.payload);
                                payloadReclamation = reponse.reclamation.payload;
                                $("#lienReclamation").addClass("flex");
                            }

                        }
                        scanDataRecu = true;
                    },
                    'error': function(xhr, status, errMsg) {
                        // Si en dev, output un message d'erreurs, les paramètres et les headers reçus.
                        if (lq.support.isDev) {
                            console.log(['Update vbel error', arguments, xhr.getAllResponseHeaders()]);
                        }
                        var messageErreur = "Vérification temporairement non disponible. Veuillez réessayer plus tard.";
                        $("#message").html(messageErreur);

                        if (!codeFormuleSuperGroupe && noControle.match(regexPFE)) {
                            $("#noControle").html(noControle + "*-***");
                        }
                        if (!codeFormuleSuperGroupe && (noControle.match(regexInstant) || noControle.match(regexPassive))) {
                            $("#noControle").html(noControle + "-***");
                        }
                        if (codeFormuleSuperGroupe && noControle.match(regexFormuleSuperGroupe)) {
                            $("#noControle").html(noControle + "-****");
                        }
                        history.pushState("etape3", "", "");
                        currentURL = window.location.href;
                        $("#etape2").addClass("hide");
                        $("#etape3").removeClass("hide");
                        $("#aide_etape3").show();
                        $('[id^="aide_etape2_"]').css('display', 'none');
                        $("#publicite_etape3").show();
                        $("#publicite_etape2").hide();
                        console.log('displayreeor from error:');
                        
                        resetEncadreFSG();
                        resetEncadreReclamation();
                        resetEncadre2eChance();
                    }
                };
            $.ajax(options);
        }

        function resetMasque() {

            if ($("#lqNumeroControle").val().length == 0 || premierCaractere != premierCaractereInitial) {
                if (masqueActif) {
                    $("#lqNumeroControle").unwrap();
                }
                $("#lqNumeroControle").unbind("change");
                $("#lqNumeroControle").unbind("click");
                $("#lqNumeroControle").unbind("keyup");
                $("#lqNumeroControle").unbind("keydown");
                $(".masque-superpose").remove();
                //IE11 n'a pas l'air de supporté un .focus seul sans timeout
                window.setTimeout(function() {
                    $('#lqNumeroControle').focus();
                }, 100);
                $("#lqNumeroControle").removeClass("border-red");
                $("#erreurSaisie").addClass("hideErreurSaisie");
                $("#erreurValidation").addClass("hideErreurValidation");
                $("#erreurCommence0").addClass("hideErreurCommence0");
                $("#erreurIncomplet").addClass("hideErreurIncomplet");
                //On remet l'event qui check le keydown
                $("#lqNumeroControle").on("keydown", checkKey);
                masqueActif = false;
                codeJEF = false;
                codePassif = false;
                codeInstant = false;
                premierCaractereInitial = premierCaractere;
            }
        }

        if (!$("#erreurVerification").text() == 'true') {
            $("#erreurValidation").removeClass("hideErreurValidation");
        } else {
            $("#erreurValidation").addClass("hideErreurValidation");
        }

        function checkKey(event) {
            key = event.keyCode;

        }
        $("#lqNumeroControle").on("keydown", checkKey);
        
        $("#lqNumeroControle").on("input", function() {
            //Idealement, ce if devrait être une fonction dans la librairie du masque
            
            //On get le premier caracteres du numero de controle pour le changement de masque en cas d'erreur de l'utilisateur
            premierCaractere = ($("#lqNumeroControle").val()).substring(0, 1);
            premierCaractere = premierCaractere.toUpperCase();
            if (premierCaractereInitial == "") {
                premierCaractereInitial = premierCaractere;
            }

            resetMasque();

            //On enleve l'erreur de code incomplet et de l'indicateur E9999 dès qu'un input utilisateur a lieu.
            $("#erreurIncomplet").addClass("hideErreurIncomplet");
            erreurIncomplet = false;
            if(erreurIndE9999 = true) {
                erreurIndE9999 = false;
                $("#erreurSaisie").addClass("hideErreurSaisie");
            }

            if (($("#lqNumeroControle").val().length == 0 || !regexInvalide.exec($("#lqNumeroControle").val())) && erreurCodeInvalide || ((!regexValidationFSG.exec($("#lqNumeroControle").val().substring(0, 29)) || regexAlphanumerique.exec($("#lqNumeroControle").val().substring(29, 34))) && erreurCodeInvalide && codeFormuleSuperGroupe)) {
                //Remove erreur code invalide.
                $("#lqNumeroControle").removeClass("border-red");
                $("#erreurSaisie").addClass("hideErreurSaisie");
                erreurCodeInvalide = false;
            }
            if ((codeJEF && erreurCodeJefInvalide && !regexAlpha.exec($("#lqNumeroControle").val())) || !codeJEF) {
                //Remove code jef invalide
                $("#lqNumeroControle").removeClass("border-red");
                $("#erreurSaisie").addClass("hideErreurSaisie");
                erreurCodeJefInvalide = false;
            }
            if (!regexCommence0.exec($("#lqNumeroControle").val())) {
                //Remove code commence par 0
                $("#lqNumeroControle").removeClass("border-red");
                $("#erreurCommence0").addClass("hideErreurCommence0");
                erreurCommence0 = false;
            }

            if ($("#lqNumeroControle").length > 0 && !masqueActif) {
                if (!codeFormuleSuperGroupe) {
                    if (regexPFE.exec($("#lqNumeroControle").val())) {
                        var masqueBillet = new MasqueSaisie("**-****-****-****-****-***", "*", "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789");
                        masqueBillet.addChamp($("#lqNumeroControle"));
                        $("#lqNumeroControle").focus();
                        masqueActif = true;
                        codeJEF = true;
                    }
                    if (regexInstant.exec($("#lqNumeroControle").val())) {
                        var masqueBillet = new MasqueSaisie("*-***-******-***-****-****-***", "*", "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789");
                        masqueBillet.addChamp($("#lqNumeroControle"));
                        $("#lqNumeroControle").focus();
                        masqueActif = true;
                        codeInstant = true;
                    }
                    if (regexPassive.exec($("#lqNumeroControle").val())) {
                        var masqueBillet = new MasqueSaisie("*-******-******-***-****-****-***", "*", "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789");
                        masqueBillet.addChamp($("#lqNumeroControle"));
                        $("#lqNumeroControle").focus();
                        masqueActif = true;
                        codePassif = true;
                    }
                }
                //Valide seulement si l'utilisateur choisi formule super groupe
                if (regexFormuleSuperGroupe.exec($("#lqNumeroControle").val()) && codeFormuleSuperGroupe) {
                    var masqueBillet = new MasqueSaisie("****-****-****-****-****-****-****", "*", "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789");
                    masqueBillet.addChamp($("#lqNumeroControle"));
                    $("#lqNumeroControle").focus();
                    masqueActif = true;
                }
                //On regarde la premiere lettre.
                if (regexCommence0.test($("#lqNumeroControle").val())) {
                    $("#erreurCommence0").removeClass("hideErreurCommence0");
                    $("#erreurValidation").addClass("hideErreurValidation");
                    erreurCodeInvalide = true;
                } else if (regexInvalide.test($("#lqNumeroControle").val()) && !codeFormuleSuperGroupe) {
                    $("#erreurSaisie").removeClass("hideErreurSaisie");
                    $("#erreurValidation").addClass("hideErreurValidation");
                    erreurCodeInvalide = true;
                } else if (regexAlphanumerique.test($("#lqNumeroControle").val()) && codeFormuleSuperGroupe) {
                    $("#erreurSaisie").removeClass("hideErreurSaisie");
                    $("#erreurValidation").addClass("hideErreurValidation");
                    erreurCodeInvalide = true;
                }
            }

            //On regarde toutes les lettres si on est un codeJEF sinon on fait la validation live d'un passif/instant
            if (codeJEF && regexAlpha.test($("#lqNumeroControle").val())) {
                $("#erreurSaisie").removeClass("hideErreurSaisie");
                $("#erreurValidation").addClass("hideErreurValidation");
                erreurCodeJefInvalide = true;
            } else if(codeInstant && (regexAlpha.test($("#lqNumeroControle").val().substring(1,16)) || regexFSGInvalide.test($("#lqNumeroControle").val().substring(16,26)))) {
                $("#erreurSaisie").removeClass("hideErreurSaisie");
                $("#erreurValidation").addClass("hideErreurValidation");
            	erreurCodeInvalide = true;
            } else if(codePassif && (regexAlpha.test($("#lqNumeroControle").val().substring(1,19)) || regexFSGInvalide.test($("#lqNumeroControle").val().substring(19,29)))) {
                $("#erreurSaisie").removeClass("hideErreurSaisie");
                $("#erreurValidation").addClass("hideErreurValidation");
            	erreurCodeInvalide = true;        
            }

            //On regarde pour les chiffres et lettres interdits si on est un code formule super groupe, mais si le premier caractere est 0, on fallback sur l'erreur commence par 0
            if (codeFormuleSuperGroupe && (regexFSGInvalide.test($("#lqNumeroControle").val().substring(0, 29))) && !regexCommence0.test($("#lqNumeroControle").val())) {
                $("#erreurSaisie").removeClass("hideErreurSaisie");
                $("#erreurValidation").addClass("hideErreurValidation");
                erreurCodeInvalide = true;
            }

            //On regarde le premier caractere pour un 0 si jamais l'utilisateur decide de mettre un 0 en premiere position
            //apres que le masque de saisie soit apparu
            if (regexCommence0.test($("#lqNumeroControle").val())) {
                $("#erreurCommence0").removeClass("hideErreurCommence0");
                $("#erreurValidation").addClass("hideErreurValidation");
                erreurCodeInvalide = true;
            }


            if (masqueActif) {
                var valeur = $("#lqNumeroControle").val();
                var last = valeur.slice(valeur.length - 1);
                //On regarde pour le backspace(8) parce que sinon on a un cas limite avec le -			
                if (regexJefCaractereSpecialInvalide.test(last) && key != 8) {
                    var aWhile = 1000; // 1 seconds
                    var doSomethingAfterAWhile = function() {
                        $("#lqNumeroControle").removeClass("border-red");
                    }
                    $("#lqNumeroControle").addClass("border-red");
                    setTimeout(doSomethingAfterAWhile, aWhile);
                }
                //$("#lqNumeroControle").off("keydown", checkKey);
            }
        });

        //On attrape le submit pour enlever le mac avant l'envoi au back end loteries.
        $("#lqFormulaireVerificationBillet").on("submit", function(e) {
            
            e.preventDefault(); //stop submit event
            var controle = $("#lqNumeroControle").val();
            var self = $(this); //this form

            billet = $('input[name="billet"]:checked').val();
            var texteLegal = "#textelegal" + billet;
            if ($(texteLegal).text().length > 0) {
                $("#notelegale").html($(texteLegal).html())
            }
            
            if (!erreurCodeInvalide && !erreurCodeJefInvalide && !erreurIncomplet && !erreurCommence0 && !erreurIndE9999) {
                if (regexPFEComplete.test($("#lqNumeroControle").val()) || regexPassiveComplete.test($("#lqNumeroControle").val()) || regexInstantComplete.test($("#lqNumeroControle").val())) {
                    //On vérifie la longueur finale du # de controle, sinon on a un cas limite d'un numero incomplet de 4-5
                    //caractères et moins qui ne réagissent pas.
                    if ((controle.length == 26 && codeJEF)) {
                        var controleSubmit = controle.slice(0, controle.length - 5);
                        $("#lqNumeroControle").val(controleSubmit); //change input
                    } else if (controle.length == 30 || controle.length == 33) {
                        var controleSubmit = controle.slice(0, controle.length - 4);
                        $("#lqNumeroControle").val(controleSubmit); //change input
                    }

                    //$("#lqFormulaireVerificationBillet").off("submit");//need form submit event off.
                    //self.submit();//submit form
                    getValidationVbel();

                } else if (codeFormuleSuperGroupe && regexFormuleSuperGroupeComplete.test($("#lqNumeroControle").val())) {
                    if (controle.length == 34) {
                        var controleSubmit = controle.slice(0, controle.length - 5);
                        $("#lqNumeroControle").val(controleSubmit); //change input
                    }
                    getValidationVbel();
                } else {
                    if (controle.length == 26 || controle.length == 29 || controle.length == 30 || (!codeFormuleSuperGroupe && controle.length == 33)) {
                        // $("#lqFormulaireVerificationBillet").off("submit");//need form submit event off.
                        //self.submit();//submit form
                        getValidationVbel();
                    } else if (scanCamera){
                        getValidationVbel();
                    }   
                    else {
                        $("#erreurIncomplet").removeClass("hideErreurIncomplet");
                        erreurIncomplet = true;
                        $("#erreurSaisie").addClass("hideErreurSaisie");
                        $("#erreurValidation").addClass("hideErreurValidation");
                        $("#erreurCommence0").addClass("hideErreurCommence0");
                    }

                }

            } else {
                var aWhile = 1000; // 1 seconds
                var doSomethingAfterAWhile = function() {
                    $("#lqNumeroControle").removeClass("border-red");
                }
                $("#lqNumeroControle").addClass("border-red");
                setTimeout(doSomethingAfterAWhile, aWhile);
            }

        });
    
        function initCamera() {
    
            if (document.getElementById("reader") !== null) {

                const onScanSuccess = (decodedText, decodedResult) => {
                    // handle the scanned code as you like, for example:
                    //console.log(`Qr code found = ${decodedText}`, decodedResult);
                    let regexURL = /\/BILLET\/(.*)$/img;
                    let result = regexURL.exec(decodedText);
                    let format = decodedResult.result.format.formatName;
                    scanDataRecu = false;

                    //On va vérifier aussi que le result contient la mention que c'est un QR code ou un pdf417
                    if (result != null && format == "QR_CODE") {
                        $("#lqNumeroControle").val(result[1]);
                        $('input[name="billet"]').prop('checked', true);
                        //Test FSG
                        //$("#lqNumeroControle").val("P44VJNMTAEVTMY3EPULGXWVJ");
                        
                        if($("#lqNumeroControle").val().match(regexFormuleSuperGroupeSansTiret)) {
                            //$('input:radio[name="billet"]').filter('[value="superGroupe"]').attr('checked', true);
                            codeFormuleSuperGroupe = true;
                        };

                        scanCamera = true;
                        scanPdf417 = false;
                        html5QrCode.pause(true);
                        callModal();
                    }else if(format == "PDF_417"){
                        $("#lqNumeroControle").val(lq.pdf417.getNoControleFromPDF417(decodedText));
                        $('input[name="billet"]').prop('checked', true);
                        scanCamera = true;
                        scanPdf417 = true;
                        html5QrCode.pause(true);
                        callModal();
                    }else {

                    }

                    erreurIncomplet = false;

                    function callModal(){
                        $("#lqFormulaireVerificationBillet").trigger("submit");
                        callTimeout();
                    }

                    function callTimeout() {
                        if(scanDataRecu) {
                            $("#contenuModalCamera").html($("#etape3").html()); 
                            $('#popupCamera').modal('show');
                        } else {
                            setTimeout(() => {
                                callTimeout();
                            }, 500);
                        }
                    }
                }
                
                const qrScanRegion = (viewfinderWidth, viewfinderHeight) => {
                    let returnValue = {};
        
                    //returnValue.width = viewfinderWidth / 2;
                    //returnValue.width = viewfinderWidth;
                    //returnValue.height = returnValue.width / 1.33;

                    if(window.matchMedia('only screen and (min-width: 575px)').matches) {
                        //Bon pour tablette
                        returnValue.width = 500;
                        returnValue.height = 300;
                    } else {
                        //Bon pour mobile
                        returnValue.width = 300;
                        returnValue.height = 500;
                    }

                    /*console.log("width init : " + viewfinderWidth);
                    console.log("height init : " + viewfinderHeight);            
                    console.log("width : " + returnValue.width);
                    console.log("height : " + returnValue.height);*/
                    return returnValue;
                }
        
                const checkForQrShadedRegion = () => {
                    let qrShadedRegion = document.getElementById("qr-shaded-region") || null;

                    if (qrShadedRegion !== null) {

                        const horizontalLine = document.createElement("div");
                        const verticalLine = document.createElement("div");
                        //const horizontalLineHautDroite = document.createElement("div");
                        //const horizontalLineHautGauche = document.createElement("div");
                        //const horizontalLineBasDroite = document.createElement("div");
                        //const horizontalLineBasGauche = document.createElement("div");
                        //const verticalLineHautDroite = document.createElement("div");
                        //const verticalLineHautGauche = document.createElement("div");
                        //const verticalLineBasDroite = document.createElement("div");
                        //const verticalLineBasGauche = document.createElement("div");
        
                        horizontalLine.className = 'horizontal-line';
                        verticalLine.className = 'vertical-line';
                        //horizontalLineHautDroite.className = 'horizontal-line-haut-droite';
                        //horizontalLineHautGauche.className = 'horizontal-line-haut-gauche';
                        //horizontalLineBasDroite.className = 'horizontal-line-bas-droite';
                        //horizontalLineBasGauche.className = 'horizontal-line-bas-gauche';
                        //verticalLineHautDroite.className = 'vertical-line-haut-droite';
                        //verticalLineHautGauche.className = 'vertical-line-haut-gauche';
                        //verticalLineBasDroite.className = 'vertical-line-bas-droite';
                        //verticalLineBasGauche.className = 'vertical-line-bas-gauche';
        
                        qrShadedRegion.replaceChildren();
                        qrShadedRegion.insertAdjacentElement('beforeend', horizontalLine);
                        qrShadedRegion.insertAdjacentElement('beforeend', verticalLine);
                        //qrShadedRegion.insertAdjacentElement('beforeend', horizontalLineHautDroite);
                        //qrShadedRegion.insertAdjacentElement('beforeend', horizontalLineHautGauche);
                        //qrShadedRegion.insertAdjacentElement('beforeend', horizontalLineBasDroite);
                        //qrShadedRegion.insertAdjacentElement('beforeend', horizontalLineBasGauche);
                        //qrShadedRegion.insertAdjacentElement('beforeend', verticalLineHautDroite);
                        //qrShadedRegion.insertAdjacentElement('beforeend', verticalLineHautGauche);
                        //qrShadedRegion.insertAdjacentElement('beforeend', verticalLineBasDroite);
                        //qrShadedRegion.insertAdjacentElement('beforeend', verticalLineBasGauche);
                        qrShadedRegion.className = "desired-state";
        
                        clearInterval(intervalCheckQrShadedRegion);
                    }
                }
        
                const html5QrCode = new Html5Qrcode("reader");
                const config = { fps: 10, qrbox: qrScanRegion, /*formatsToSupport: [
                    Html5QrcodeSupportedFormats.QR_CODE,
                    Html5QrcodeSupportedFormats.PDF_417,
                ],*/ rememberLastUsedCamera: false, defaultZoomValueIfSupported: 2 };
        
                html5QrCode.start({ facingMode: "environment" }, config, onScanSuccess);            
        
                var intervalCheckQrShadedRegion = setInterval(checkForQrShadedRegion, 250);

                function stopCamera() {             
                    html5QrCode.stop();
                    setTimeout(() => {
                        html5QrCode.clear();
                      }, "1000");
                };

                $("#iconeBack").on("click.stopCamera", function stopCameraEvent(e){
                    stopCamera();
                });
                
                $(".fermerCamera").on("click.resumeCamera", function (e){
                    if(scanCamera) {
                        html5QrCode.resume();
                    }
                    resetModal();
                });
            }
        }

        $("#boutonScanCamera").on("click", function(e) {
            goScanCamera();
            initCamera();
        });

        $("#iconeAide").on("click", function(e) {
            $('#contenuModalCamera').html("<div id='wrapperAide'></div>");
            $('#wrapperAide').html($("#aide_etape2Camera").html());
            $('#popupCamera').modal('show');
        });
    }, 100);

    var estDefini = function(obj) { return (obj !== undefined); };

    var saveStats = function(categorie, action) {
        var
            argv = arguments,
            argc = arguments.length,
            label = (argc > 2) ? argv[2] : null;

        if (estDefini(ga)) {
            //console.log("Save Stats : categorie=" + categorie + ", action=" + action + ", label=" + label);
            if (label === null) {
                ga('send', 'event', categorie, action);
            } else {
                ga('send', 'event', categorie, action, label);
            }
        }
    };

    // Update global scope lq namespace
    window.lq = lq;
    // Auto trigger all self implemented (plugin) functionalities

}(jQuery, document, window, (window.lq || {})));