(function($, document, window, lq) {
	const CTX_PATH_MGN_AUTHOR = '/magnoliaAuthor/';
	const NUM_PRODUIT_LOTTO649 = "212";
	const NUM_PRODUIT_LOTTOMAX = "223";
	const NUM_PRODUIT_LOTTO_POKER = "226";


	async function getResultatSommaireProduit(noProduits) {

		const lang = lq.support.lang;

        const servicesURI = lq.configs.srvDomain + '/loteries-endpoints/' + lang + '/resultats/sommaires/noproduits';

		const noProduitParam = lq.commun.getParameterByName("urlid", window.location.href);
		
		const scenarioParam = lq.commun.getParameterByName("scenario", window.location.href);
		
		let params;
		if(scenarioParam){
			params = noProduits.map(noProduit => "noProduits=" + scenarioParam + noProduit).join("&");	
		}else{
			params = noProduits.map(noProduit => "noProduits=" + noProduit).join("&");
		}

		

		params += noProduitParam !== "" ? "&urlid=" + noProduitParam : "";

		const response = await $.ajax({
				url: servicesURI + "?" + params,
				context: document.body
		  	}).done(function(json) {
		});

		return response;
	}

	const getLotProgressifPoker = async () => {

        const servicesURI = lq.configs.srvDomain + '/loteries-endpoints/lotprogressifpoker';

		const response = await $.ajax({
				url: servicesURI,
				context: document.body
		  	}).done(function(json) {
		});

		return response.lotProgressif;
	}

	const populateDOM = async (produit, pagesProduit) => {

		//var productsMap =  await lq.commun.getProductsMap();
		if (isNaN(produit.noProduit)) {
        	produit.noProduit = produit.noProduit.replace(/\D/g, '');
        }
		const wrapper = document.createElement('div');
		wrapper.innerHTML = produit.html;

		const $resultatSommaire=$(wrapper).find(".lqConteneurGeneral");
		$( ".resultats-sommaires-produit-" + produit.noProduit ).html( $resultatSommaire );
		$( ".resultats-sommaires-produit-" + produit.noProduit ).parent().attr('data-no-produit', produit.noProduit);

		const produitTheme = $(".produit-" + produit.noProduit).closest(".produit-placeholder").attr("data-produit-theme");

		let dateRetrait = $(".produit-" + produit.noProduit).closest(".produit-placeholder").attr("data-date-retrait");
		dateRetrait = !!dateRetrait && new Date(dateRetrait);

		let dateDebutAffichage = $(".produit-" + produit.noProduit).closest(".produit-placeholder").attr("data-date-debut-affichage");
		dateDebutAffichage = !!dateDebutAffichage && new Date(dateDebutAffichage);

		let dateFinAffichage = $(".produit-" + produit.noProduit).closest(".produit-placeholder").attr("data-date-fin-affichage");
		dateFinAffichage = !!dateFinAffichage && new Date(dateFinAffichage);

		const isAffichable = await aAfficher(dateDebutAffichage, dateFinAffichage, dateRetrait);

		$(".produit-" + produit.noProduit).find(".logo").attr("href", pagesProduit[produit.noProduit]);
		$(".produit-" + produit.noProduit).find(".lienResultatsComplets").attr("href", pagesProduit[produit.noProduit]);

		const hasRemorque = $( ".produit-" + produit.noProduit ).find(".remorque").length > 0;

		if (hasRemorque) {
			$( ".produit-" + produit.noProduit + ".contenantResultat").addClass("hasRemorque");
		}

		if(produit.noProduit === "239") {
			$(".numero_gn .num_gn").wrap("<div class='imgwrapper'></div>");
		}



		if (isAffichable) {
			$( ".produit-" + produit.noProduit ).closest(".produit-placeholder").show();
		}
	}

	const afficherBoutonAcheter = async (dateRetrait) => {
		if (typeof dateRetrait === "object" && dateRetrait !== null && dateRetrait < now) {
			return false;
		}

		return true;
	}


	const aAfficher = async (dateDebutAffichage, dateFinAffichage, dateRetrait) => {
		const now = await lq.commun.getDate();

		if (typeof dateDebutAffichage === "object" && dateDebutAffichage !== null && dateDebutAffichage > now) {
			console.log('now: ',now, 'dateDebutAffichage:', dateDebutAffichage, 'condition: ', dateDebutAffichage > now)
			return false;
		}

		if (typeof dateFinAffichage === "object" && dateFinAffichage !== null && dateFinAffichage < now) {
			return false;
		}

		return true;
	}

    lq.resultatsdetailles = (function() {

		let produitsLocal = [];
		let pagesProduitLocal = {};

		// listeProduitPageResultat vient du FTL resultats-recents.ftl
		produitsLocal =  typeof listeProduitPageResultat !== "undefined" ? listeProduitPageResultat : [];

		// pagesProduit vient du FTL resultats-recents.ftl
		pagesProduitLocal =  typeof pagesProduit !== "undefined" ? pagesProduit : {};

		if (produitsLocal.length === 0) {
			return {initResult: function(){}}
		}

        return {

            initResult: async function() {

				async function getResultatsSommaires() {
					const produits = await getResultatSommaireProduit(produitsLocal);
					produits.map(produit => populateDOM(produit, pagesProduitLocal));

					const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
				}

				getResultatsSommaires().finally( () => {


						const togglePlusOptions = (e,element) => {
							const noProduit = $(element).closest(".produit-placeholder").attr("data-no-produit");
							if (noProduit  !== undefined) {
								$(".produit-placeholder.produit-" + noProduit).find(".plus-options").toggleClass("ouvert");
							}
						}

						$(".produit-placeholder .bouton-affichage").on('click', function(e) {
							togglePlusOptions(e, this);
						});

						$(".produit-placeholder .bouton-affichage-container .texte").on('click', function(e) {
							togglePlusOptions(e, this);
						});

						$('.resultats-recents.resultat-sommaires').closest('html').addClass('root-resultats-recents');
					}
				)

        	}
        };
	})();



    window.lq = lq;
    //--> Auto trigger all self implemented functionalities on document ready
    lq.load.push(
        "lq.resultatsdetailles", [
            'initResult'
        ]
    );

}($, document, window, (window.lq || {})));
