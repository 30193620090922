/*! LQ NameSpace | Copyright 2015 Loto Quebec Inc. */
/*global jQuery,lq,console*/
/*jshint -W030*/
(function ($, document, window, lq) {
	'use strict';

	/**
	 * Enable FastClick plugin
	 */
	function fastClickSetup() {
		/**
		 * Fastclick
		 *
		 * Enables FastClick plugin to deactivate 300ms delay on mobiles devices
		 * Example to ignore Fastclick on an element : <a class="needsclick">Ignored by FastClick</a>
		 *
		 */
		if ('FastClick' in window && 'addEventListener' in document) {
			//lq.console.log('Enabling FastClick...');
			document.addEventListener('DOMContentLoaded', function() {
				FastClick.attach(document.body);
			}, false);
		}
	};

	/**
	 * Create load sub name space of lq (Loto Quebec) namespace
	 * @requires lq.support
	 * @requires lq.console
	 **/
	var $W = $(window), // reference to window object
		$body = $('body'), // Reference to body element
		$D = $(document); // Reference to document element

	/**
	 * Define load sub-namespace object.
	 * @namespace load
	 * @memberOf lq
 	 */
	lq.load = (function() {
		// Private properties
		// Object containing different scope properties for execution
		var funcs = {},
		// Functions push with public "ready" method. Those function are execute before "funcs"
			waitReady = [];

		/**
		 * Private: Add an execution scope. This define an array to push methods for execution on document ready.
		 * @param {string} scope Namespace name of the scope
		 * @returns {array} Newly scope array.
		 */
		function _addScope(scope) {
			if(!funcs[scope]) {
				funcs[scope] = [];
			}
			return funcs[scope];
		}
		/**
		 * Private: Add an execution methods to a specific scope.
		 * @param {string} scope Name of namespace to add the method to for execution
		 * @param {string|Array|function} method Name of the method to execute in the namespace or an anonymous function. Can be an array of methods to push more than one method in a single call.
		 * @param {array} [args] Opional - Arguments array to pass to the method on execution
		 * @returns {array} Scope array
		 */
		function _push(scope, method, args) {
			var i;
			if(!funcs[scope]) {
				_addScope(scope);
			}
			// Register more than one functions
			if($.isArray(method)) {
				// Loop in method array
				for(i=0;method[i];i++) {
					// If current method is an array, this refer to a method with arguments to be pass on execution. Use this format [method, [arguments_list]]
					if($.isArray(method[i])) {
						_push(scope, method[i][0],  method[i].slice(1));
					} else {
						_push(scope, method[i]);
					}
				}
			} else {
				funcs[scope].push([method, (args||[])]);
			}
			// Return the scope array
			return funcs[scope];
		}
		/**
		 * Private: Execute all registered methods added with _push
		 * @returns {undefined}
		 */
		function _exec() {
			var space, scope, func, args, isFunc, funcName, i;
			if(lq.console) {
				lq.console.time('All registered Group & Events:');
			}
			// Loop through all scope in funcs
			for(scope in funcs) {
				if(funcs.hasOwnProperty(scope)) {
					space = lq.support.parseNameSpace(scope);
					if(lq.console) {
						lq.console.group((space ? scope : 'window') + '" registered methods:');
						lq.console.time('Group "' + (space ? scope : 'window') + '" execution time:');
					}
					for(i=0; funcs[scope][i]; i++) {
						isFunc = $.isFunction(window[funcs[scope][i]]); //$.isArray(funcs[scope][i]);
						// A function was added without a defined namespace or out of a namespace context
						if((space===false) || isFunc) {
							funcName = "anonymous";
							// No arguments function
							if(isFunc) {
								func = funcs[scope][i];
								args = [];
							// An array indicate a proper add with a push with arguments array [method, [args]].
							} else if ($.isArray(funcs[scope][i]) && funcs[scope][i].length === 2) {
								func = funcs[scope][i][0];
								args = funcs[scope][i][1];
								if(!$.isFunction(func)) {
									if(lq.console) {
										lq.console.warn("L'index 0 doit être une fonction pour être exécuté correctement via le _exec!", funcs[scope][i], func, args);
									}
									isFunc = func = false;
								}
							}
						// Name space found
						} else {
							// No arguments function
							if(!$.isArray(funcs[scope][i])) {
								funcName = $.isFunction(space[funcs[scope][i]]) ? funcs[scope][i] : ((!!space[funcs[scope][i]] && $.isFunction(space[funcs[scope][i]].init)) ? funcs[scope][i]+".init" : "unknown!");
								func = $.isFunction(space[funcs[scope][i]]) ? space[funcs[scope][i]] : ((!!space[funcs[scope][i]] && $.isFunction(space[funcs[scope][i]].init)) ? space[funcs[scope][i]].init : false);
								args = [];
								isFunc = (!!func);
							// An array indicate a proper add with a push with arguments array [method, [args]].
							} else if ($.isArray(funcs[scope][i]) && funcs[scope][i].length === 2) {
								func = funcs[scope][i][0];
								funcName = $.isFunction(space[func]) ? func : ((!!space[func] && $.isFunction(space[func].init)) ? func+".init" : "unknown!");
								func = $.isFunction(space[func]) ? space[func] : ((!!space[func] && $.isFunction(space[func].init)) ? space[func].init : false);
								args = funcs[scope][i][1];
								if(!$.isFunction(func)) {
									if(lq.console) {
										lq.console.warn("L'index 0 doit être une fonction pour être exécuté correctement via le _exec!", funcs[scope][i]);
									}
									func = false;
								}
								isFunc = (!!func);
							}

						}
						if(lq.console) {
							lq.console.time('Apply name space "' + (space ? scope : 'window') + '.' + funcName+'"...');
						}
						if(isFunc) {
							func.apply(this, args);
						} else {
							if(lq.console) {
								lq.console.warn("load._exec failed to execute ", scope, funcs[scope][i]);
							}
						}
						if(lq.console) {
							lq.console.timeEnd('Apply name space "' + (space ? scope : 'window') + '.' + funcName + '"...');
						}
					}
					if(lq.console) {
						lq.console.groupEnd();
						lq.console.timeEnd('Group "' + (space ? scope : 'window') + '" execution time:');
					}
				}
			}
			if(lq.console) {
				lq.console.timeEnd('All registered Group & Events:');
				lq.console.log('Reflow Foundation...');
				$(document).foundation('reflow');
			}
			// Reset funcs
			funcs = {};
		}
		// Accès public aux méthodes
		return {
			addScope: function(scope) {
				return _addScope(scope);
			},
			push: function(scope, method, args) {
				return _push(scope, method, args);
			},
			getFuncs: function() {
				return funcs;
			},
			run: function() {
				for(var f in waitReady) {
					if(waitReady.hasOwnProperty(f)) {
						if($.isFunction(waitReady[f])) {
							waitReady[f]();
						} else {
							//lq.console.warn("run.waitReady failed to execute ", f, waitReady[f]);
							console.warn("run.waitReady failed to execute ", f, waitReady[f]);
						}
					}
				}
				_exec();
			},
			ready: function(func) {
				waitReady.push(func);
			}
		};
	})();


	// Update global scope lq namespace
	window.lq = lq;

	// Auto trigger all self implemented (plugin) functionalities on document ready
	$(function () {
//		fastClickSetup();
		lq.load.run();
	});
}(jQuery, document, window, (window.lq || {})));
