/*global jQuery,lq,console, ResponsiveBootstrapToolkit*/
/*jshint -W030*/
(function ($, document, window, lq) {
	// 'use strict';

	var $W = $(window),
		$body = $('body'),

		// Configs - viewport size class prefix added to body tag
		vpSizePrefix = 'vp-',
		vpSizeClasses = {
			'xsmall': 450, // Low res phone
			'small': 767, // Low res phone
			'medium': 1200, // Phone to tablette
			'large': 1440, // large Desktop display
			'xlarge': 1920 // Extra-large Desktop display
		},
		vpMatchClasses = (function() {
			var res = '';
			$.each(vpSizeClasses, function(key) {
				res += vpSizePrefix + key + ' ';
			});
			return res.substr(0, res.length-1);
		}()),
		vpMatchSize = vpMatchClasses.replace(/vp-/g, '').replace(/\s/g, '|');

	// Define or extend viewport sub-namespace.
	lq.viewport = $.extend((lq.viewport || {}), {

        currentViewportSize: null,

		//--> Self implemented functionalities

		/**
		 * Functionality to handle class orientation and affix plugin class on body elements.
		 * @returns {void}
		 * @function orientationClassHandler
		 * @memberOf lq.viewport
		 **/
		orientationClassHandler: function () {
			var eventNameSpace = '.lq.viewport.orientationClassHandler';
            
			/**
			 * Bind event to detect orientation change and resize to add orientation class on body
			 **/
			$W.on('load' + eventNameSpace + ' orientationchange' + eventNameSpace + ' resize' + eventNameSpace, function () {
				var isLandscape = lq.viewport.isLandscape(),
                    isMobile = lq.viewport.isMobile();
				// Orientation class
				$body.removeClass('vp-' + (isLandscape ? 'portrait' : 'landscape')).addClass('vp-' + (!isLandscape ? 'portrait' : 'landscape'));
				
                // Body class handler
				$body.removeClass(vpMatchClasses).addClass(vpSizePrefix + lq.viewport.current());

                //Mobile or desktop class
                $body.removeClass('vp-' + (isMobile ? 'desktop' : 'mobile')).addClass('vp-' + (isMobile ? 'mobile' : 'desktop'));

			});
		},

		//--> Utilities methods

		/**
		 * Expose a copy of vpSizeClasses
		 * @returns vpSizeClasses
		 * @function getSizes
		 * @memberof lq.viewport
		 */
        getSizes: function() {
            return vpSizeClasses;
        },

		/**
		 * Get current viewport size in PX
		 * @returns {int}
		 * @function getPxSize
		 * @memberof lq.viewport
		 */
        getPxSize: function() {
            return window.innerWidth;
        },

		/**
		 * Use to find a size by viewport size name
		 * @param sizeName Any value defined in vpSizeClasses
		 * @returns {*} Size matching the requested sizeName, or null if name is not found in vpSizeClasses
		 * @function getSizeByName
		 * @memberof lq.viewport
		 */
        getSizeByName: function(sizeName) {
			sizeName = sizeName.toLowerCase();
			return (sizeName in vpSizeClasses) ? vpSizeClasses[sizeName] : null;
        },

		/**
		 * Test a request size name or query against the current viewport size. Use any viewport size name defined in vpSizeClasses with optional prefix '>', '>=', '<', '<='.
		 * @param size
		 * @returns {boolean}
		 */
		is: function(size) {
			var regExp = new RegExp('^([<>])?(\=)?(' + (vpMatchSize + '|[0-9]+') + ')$'),
				req = regExp.exec(size) || [];

			/*
			 * req is = [
			 *      0 => 'OriginaleSize', // i.e '<md', 'xl', '>=sm', '<=lg'
			 *      1 => '<|>'|undefined, // undefined if the first character is not < or >
			 *      2 => '='|undefined, // undefined if the second character is not =
			 *      3 => 'sizeToTest'|undefined // one of the value find in vpSizeClasses or undefined
			 * ]
			 */
			if(req.length == 4) {
				var smaller = req[1] == '<',
					larger = req[1] == '>',
					eq = req[2] == '=' || (req[1] == undefined && !(smaller || larger)),
					sz = req[3] && vpSizeClasses[req[3]] ? req[3] : false,
					intSz = (!sz && !isNaN(req[3])) ? req[3] : false,
					cur = lq.viewport.current(),
				    curPx = lq.viewport.getPxSize();

				// lq.console.log([smaller, larger, eq, sz, cur]);
				// If requested size exist in vpSizeClasses
				if(sz) {
					if((sz == cur && eq) || // equality is true and requested size is equal to current viewport size
						(smaller && vpSizeClasses[cur] < vpSizeClasses[sz]) || // can be smaller and requested size is smaller than current viewport size
						(larger && vpSizeClasses[cur] > vpSizeClasses[sz])) // can be larger and requested size is larger than current viewport size
					{
						return true;
					}
				} else if (intSz) {
					if((intSz == curPx && eq) || // equality is true and requested size is equal to current viewport size
							(smaller && curPx < intSz) || // can be smaller and requested size is smaller than current viewport size
							(larger && curPx > intSz)) // can be larger and requested size is larger than current viewport size
					{
						return true;
					}
				}
			}
			// lq.console.log({'regExp':regExp, 'size': size, 'cur': cur, 'req': req});
			return false;
		},

		/**
		 * Get current viewport width size name
		 * @returns {string}
		 * @function current
		 * @memberof lq.viewport
		 */
		current: function() {
			return (lq.viewport.applicable()).pop();
		},

		/**
		 * Get an array of all size name smaller or equal to current window width (viewport width)
		 * @returns {Array}
		 * @function applicable
		 * @memberof lq.viewport
		 */
		applicable: function() {
			var classes = [],
				lowerBound = 0,
				w = window.innerWidth; //$W.width();

			$.each(vpSizeClasses, function(name, val) {
				if(lowerBound < w) {
					classes.push(name);
					lowerBound = val;
				} else {
					// break the loop here
					return false;
				}
			});
			return classes;
		},


		/**
		 * Method to know if resolution is a "mobile". All resolution less than (md) 1025px wide return true
		 * @param {int} [size=window.innerWidth] Optional size to test. Default use current window.innerWidth
		 * @returns {Boolean} True if size is less than 768px wide
		 * @function isMobile
		 * @memberOf lq.viewport
		 **/
		isMobile: function (size) {
			return (size || window.innerWidth) <= vpSizeClasses.medium;
		},

		/**
		 * Method to know if orientation is landscape base on optional parameters or screen resolution.
		 * @param {int} [w=window.innerWidth] Optional width to test. Default use current window.innerWidth
		 * @param {int} [h=window.innerHeight] Optional height to test. Default use current window.innerHeight
		 * @returns {Boolean} True if width is greater or equal to height
		 * @function isLandscape
		 * @memberOf lq.viewport
		 **/
		isLandscape: function (w, h) {
			return (w || window.innerWidth) >= (h || window.innerHeight);
		},

		/**
		 * Method to know if orientation is portrait base on optional parameters or screen resolution.
		 * @param {int} [w=window.innerWidth] Optional width to test. Default use current window.innerWidth
		 * @param {int} [h=window.innerHeight] Optional height to test. Default use current window.innerHeight
		 * @returns {Boolean} True if height is greater than height !landscape
		 * @function isPortrait
		 * @memberOf lq.viewport
		 **/
		isPortrait: function (w, h) {
			return !lq.viewport.isLandscape(w, h);
		},

		/**
		 * Viewport special event handler
		 * @param evName
		 * @param $el
		 * @param callback
		 */
		on: function (evName, $el, callback) {
			var evNameSpace = lq.utils.uId('_' + evName + '_');

			$W.on('load.' + evNameSpace + ' scroll.' + evNameSpace +' viewportInOut.' + evNameSpace, +' enterScreen.' + evNameSpace, +' exitScreen.' + evNameSpace, function (e) {
				//lq.console.log(e.type);
				if(lq.viewport.inPort($el)) {
					if(!$el.data('in-port')) {
						$el.data('in-port', true);
						//console.log('in-port');
						if(evName == 'enterScreen' && e.type != 'exitScreen') {
							//lq.console.log('trigger enter callback');
							callback.call();
						}
					}
				} else {
					if($el.data('in-port')) {
						$el.data('in-port', false);
						// lq.console.log('out-port');
						if(evName == 'exitScreen' && e.type != 'enterScreen') {
							callback.call();
						}
					}
				}
			});

			window.setTimeout(function () {
				$W.trigger('viewportInOut.' + evNameSpace);
			}, 300);
		},

		/**
		 * Get top and bottom position of current viewport related to window size ans scroll position
		 * @returns {{top: *, bottom: *}}
		 */
		coords: function () {
			var top = $W.scrollTop(),
				bottom = top + $W.height();

			return {
				'top': top,
				'bottom': bottom
			}
		},

		/**
		 * Calculate if an element is currently entirely inside the viewport
		 * @param $el jQuery elements to test
		 * @returns {boolean}
		 */
		inPort: function ($el) {
			var port = lq.viewport.coords(),
				elTop = $el.offset().top;

			return (elTop > port.top && elTop + $el.height() < port.bottom)
		}
	});

	// Update global scope lq namespace
	window.lq = lq;
	
	// Auto trigger all self implemented functionalities on document ready
	lq.load.push(
		"lq.viewport",
		[
			'orientationClassHandler'
		]
	);

}($, document, window, (window.lq || {})));