(function($, document, window, lq) {
    var weekDays = ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'];
    var dateGlobale = null;

    lq.resultatssommaires = (function() {
        async function getResults(noproduit) {
            var lang = lq.support.lang,
                servicesURI = lq.configs.srvDomain + '/loteries-endpoints/' + lang + '/resultats/sommaires/' + noproduit,
                options = {
                    'type': 'GET',
                    'url': servicesURI,
                    'dataType': 'html',
                    'cache': true,
                    'success': function(data) {
                        //Fix pour les tests
                        if (isNaN(noproduit)) {
                            noproduit = noproduit.replace(/\D/g, '');
                        }

                        lq.commun.getProductMap(noproduit).then(async () => {

                            lq.commun.getProductName(noproduit).then(
                                (productName) => {
                                    $("#selecteurProduits").html(productName)
                                }
                            );

							if ($.trim(data) == undefined || $.trim(data) == null || $.trim(data) == "" ){
								console.log('displayreeor from error:' + status + "   " + errMsg);

		                        var messageErreur = "<div class='lqConteneurGeneral'><div class='lqPlaceHolderConteneurErreurGeneral'></div><div class='lqZoneErreur'><div class='lqZoneMessageNonDisponibilite'>";
		                        if (lang == 'en') {
		                        	messageErreur += "Complete results to come.";
		                        }else{
		                        	messageErreur += "R&eacute;sultats complets &agrave; venir.";
		                        }
		                        messageErreur += "</div></div></div>";
		                        $(".contenantResultat").html(messageErreur);

		                	} else {
                                const data_replaced = await replaceNoProduitByUrl(data, lang);
                                $(".contenantResultat").html(data_replaced);

	                            var lienResultatsDetailles = $('.lienResultatsDetailles').text().replace('>', '').trim();
	                            var indexTiret = lienResultatsDetailles.indexOf(" - ") + 3;
	                            var textLienResultatsDetailles = lienResultatsDetailles.substring(0, indexTiret);
	                            textLienResultatsDetailles += lienResultatsDetailles.substring(indexTiret).split(" ").join('\xa0');
	                            $('.lienResultatsDetailles').text(textLienResultatsDetailles);
	                            $(".lienResultatsDetailles").wrap('<div class="btn-resultats-sommaires"></div>');

	                            var sDateRetrait = lq.commun.getProductExpirationDate(noproduit);
	                            var typeProduit = lq.commun.getProductType(noproduit);
	                            var urlProduit = lq.commun.getProductPage(noproduit);
	                            var sBoutonJouer = lq.commun.getProductPlayButtonLabel(noproduit);
	                            var dateRetrait = new Date(sDateRetrait);
	                            var productName = lq.commun.getProductName(noproduit);

	                            //TODO : obtenir la date logique
	                            var dateCourante = lq.commun.getDate();

	                            Promise.all([sDateRetrait, typeProduit, urlProduit, sBoutonJouer, productName, dateCourante]).then((values) => {
	                                const [sDateRetrait, typeProduit, urlProduit, sBoutonJouer, productName, dateCourante] = values;

	                                if ((typeof(sDateRetrait) == "undefined" || sDateRetrait == "" || dateCourante < dateRetrait) &&
	                                typeof(typeProduit) != "undefined" && typeProduit.indexOf("en-ligne") != -1) {
	                                    $(".btn-resultats-sommaires").append('<a href= \'' + urlProduit + '\';" class="btn btn-outline-primary opaque texte-bouton" >' +
	                                        sBoutonJouer + '<span class="sr-only">&nbsp;' +
	                                        productName + '</span></a>');
	                                }
	                            })
                            }
                        });

                    },
                    'error': function(xhr, status, errMsg) {
                        // Si en dev, output un message d'erreurs, les paramètres et les headers reçus.
                        if (lq.support.isDev) {
                            //lq.console.log(['Update resultatssommaires error', arguments, xhr.getAllResponseHeaders()]);
                            console.log(['Update resultatssommaires error', arguments, xhr.getAllResponseHeaders()]);
                        }
                        //lq.console.log('displayreeor from error:');
                        console.log('displayreeor from error:' + status + "   " + errMsg);

                        var messageErreur = "<div class='lqConteneurGeneral'><div class='lqPlaceHolderConteneurErreurGeneral'></div><div class='lqZoneErreur'><div class='lqZoneMessageNonDisponibilite'>";
                        if (lang == 'en') {
                        	messageErreur += "Complete results to come.";
                        }else{
                        	messageErreur += "R&eacute;sultats complets &agrave; venir.";
                        }
                        messageErreur += "</div></div></div>";
                        $(".contenantResultat").html(messageErreur);
                    }
                };
            $.ajax(options);
        }

        async function replaceNoProduitByUrl(data, lang) {

            var reg = /(\&\#36\;|\$)\(noProduit=\d{1,10}\)/g,
                regexNumero = /[^#36;=\$]\d{1,10}/,
                noProduit,
                pageProduitComplete,
                pageProduitFin, //On garde seulement la fin du lien car le reste nous arrive de GBA
                errorProduit = false,
                msgError = "R&eacute;sultats non disponibles. Veuillez revenir plus tard.";

            const productsMap = await lq.commun.getProductsMap();

            if (data.match(reg)) {
                data.match(reg).forEach( (element) => {
                    noProduit = element.match(regexNumero);
                    try {
                        pageProduitComplete = productsMap[noProduit]['pageProduit'];
                        pageProduitFin = pageProduitComplete.substring(pageProduitComplete.lastIndexOf('/') + 1);
                        data = data.replace(element, pageProduitFin);
                    } catch (error) {
                        console.log("Ça plante : ", error);
                        errorProduit = true;
                    }

                    if (errorProduit) {
                        if (lang == 'en') {
                            msgError = "Results not available. Please try again later."
                        }
                        return "<div class='lqConteneurGeneral'><div class='lqPlaceHolderConteneurErreurGeneral'></div><div class='lqZoneErreur'><div class='lqZoneMessageNonDisponibilite'>" + msgError + "</div></div></div>";
                    } else {
                        return data;
                    }
                });
            }

            return data;
        }
        return {
            initResult: function() {
                if ($('.resultat-sommaires').not( ".resultats-recents" ).length > 0) {
                    //Recherche du produit par defaut
                    var noProduitDefaut;
                    var noProduitURL; // RR

                    lq.commun.getDate().then(
                        (date) => {
                            if (listeProduitsDefaut["enDateReel"] == 'false' && date !== null) {
                                dateGlobale = date;
                            } else {
                                dateGlobale = new Date();
                            }
                            noProduitURL = lq.commun.getParameterByName("urlid", window.location.href); //RR

                            if (noProduitURL !== "") { //RR
                                noProduitDefaut = noProduitURL; //RR
                            } else if (typeof produitHub !== "undefined" && produitHub !== null) {
                            	noProduitDefaut = produitHub;
                            } else { //RR
                                noProduitDefaut = listeProduitsDefaut[weekDays[dateGlobale.getDay()]];
                            } //RR

                            lq.commun.getProductMap().then( async () => {

                                lq.commun.getProductName(noProduitDefaut.replace(/\D/g, '')).then((productName)=>(
                                    $("#selecteurProduits").html(productName)
                                )).then( async () => (await getResults(noProduitDefaut)));

                                $("#selecteurProduits").click(function() {
                                    position = $("div.resultat-sommaires.gauche").offset();
                                    window.scrollTo(0, position.top);
                                });

                                $('.dropdown-item').click(function() {

                                    lq.commun.getProductName($(this).attr("value")).then(
                                        (productName) => {
                                            $("#selecteurProduits").html(productName);
                                            getResults($(this).attr("value"));
                                            position = $("div.resultat-sommaires.gauche").offset();
                                            window.scrollTo(0, position.top);
                                        }
                                    );
                                });
                            });
                        }
                    );
                }
            }
        };

    })();

    window.lq = lq;
    //--> Auto trigger all self implemented functionalities on document ready
    lq.load.push(
        "lq.resultatssommaires", [
            'initResult'
        ]
    );

}($, document, window, (window.lq || {})));