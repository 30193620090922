(function($, document, window, lq) {
	
	lq.menu = (function(){
        function fermerTousSousMenus() {
            $("#lqZoneMenuCompletOnglets .lqZoneMenuOngletNiveau1").removeClass("menuActuel");
            $("#lqZoneMenuCompletOnglets .lqZoneMenuOngletNiveau1 .lqFlecheSubMenu").removeClass("iconeActuelle");
            $("#lqZoneMenuCompletOnglets .lqZoneMenuOngletNiveau1 .menuPetit").css("display", "none");
        }

        function toggleMenuDisplay(boutonMenu) {
            $('.iconeMenu', boutonMenu).toggle();
            $('.iconeMenuFermer', boutonMenu).toggle();
            var menu = $("#lqZoneMenuComplet");
            if(menu.hasClass("menuVisibleMobile")){
                menu.removeClass("menuVisibleMobile");
                fermerTousSousMenus();
            }else{
                menu.addClass("menuVisibleMobile");
            }
        }

        function toggleTabDisplay(onglet) {
            var sousMenuCourant = $('.menuPetit', onglet);
            var iconePlus = $('.lqFlecheSubMenu', onglet);
            if (onglet.hasClass("menuActuel")) {
                iconePlus.removeClass("iconeActuelle");
                sousMenuCourant.css("display", "none");
                onglet.removeClass("menuActuel");
            } else {
                fermerTousSousMenus();
                
                sousMenuCourant.css("display", "block");
                onglet.addClass("menuActuel");
                iconePlus.addClass("iconeActuelle");
            }
        }
        
        contientItems = function (idClass) {

            var auMoinUnLienAffiche = false;

            $("#" + idClass + " li").each(function () {

                if ($(this).hasClass("affichageGeneral") || $(this).hasClass("loggue")) {

                    auMoinUnLienAffiche = true;
                    return false;
                }
            });

            return auMoinUnLienAffiche;
        }
        
        afficherSousMenuGeneral = function (ongletId) {

            var auMoinUnLienAffiche = false;

            var idGroupe = ongletId;
            
            auMoinUnLienAffiche = contientItems("LiensMenuGeneral_" + idGroupe);
            console.log("auMoinUnLienAffiche : " + auMoinUnLienAffiche);
            console.log("affiche : " + "#LiensMenuGeneral_" + idGroupe);
            if (auMoinUnLienAffiche) {
                $("#LiensMenuGeneral_" + idGroupe).addClass("show");
            } else {
              $("#LiensMenuGeneral_" + idGroupe).removeClass("show");
            }
        }
             
		function getMenu(){
			var lang = lq.support.lang,
				servicesURI = lq.configs.srvDomain + '/loteries-endpoints/' + lang + '/menu',
				options = {
                'type': 'GET',
                'url': servicesURI,
                'dataType': 'html',
                'cache': true,
                'success': function (data) {
                	if ($.trim(data) == undefined || $.trim(data) == null || $.trim(data) == "" ){
                		console.log('Erreur magnolia 5 indisponible ');
                	} else {
                		$("#lqZoneMenuComplet").html(data);
                	}
                    //Pour déterminer l'onglet par défaut selon la page visitée
                    var adresse = window.location.pathname.split("/");
                    //Au cas où la page est suivie d'un .html
                    var page = adresse[adresse.length - 1].split(".")[0];
                    var boutonMenu = $('#lqBoutonZoneMenuComplet');
                    boutonMenu.click(function() {
                        if (typeof connexionLq !== "undefined" && connexionLq !== null){
                			connexionLq.gestionMenuJouerEnLigne();
                		}
                        toggleMenuDisplay(boutonMenu);
                    });
                    $('.lqZoneMenuOngletNiveau1').each(function() {
                        var onglet = $(this);
                        //Code à des fins de tests, permet de visualiser le menu sur une page autre que l'accueil (ex: /jeux) mais seulement en français
                        var ongletName = $("a", this).attr("href").split("/")[2];
                        var ongletId = $("a", this).attr("id");

                        //On doit vérifier si le lien de l'onglet apparait dans l'url complete ou verifier la page parent.
                        //if ($("a", this).attr("href").includes(ongletName)) {
                    	if (window.location.pathname.includes(ongletName)) {
                            onglet.addClass("ongletParDefault");
                            //$("#LiensMenuGeneral_idMenuInfoPratiques").css("display", "block");
                            $("#LiensMenuGeneral_" + ongletId + " .lqZoneLienOnglet .lqZoneMenuLienInterne").each(function (index, element) {
                              if ($(element).attr("href").includes(page) && ongletName != page) {
                                $(element).addClass('pageActuelle');
                              }
                            });
                            afficherSousMenuGeneral(ongletId);
                        } else {
                            onglet.removeClass("ongletParDefault");
                        }

                        // Corriger erreur d'accessibilité (HTML Code Sniffer)
                        $('.lqFlecheSubMenu').html("<span class='sr-only'>bouton</span>");

                        //Permettre d'afficher un sous-menu lorsqu'on clique le bouton du hamburger menu
                        $('.lqFlecheSubMenu', this).click(function() {
                            toggleTabDisplay(onglet);
                        });
                    });
				},
                'error': function (xhr, status, errMsg) {
                    // Si en dev, output un message d'erreurs, les paramètres et les headers reçus.
                    if (lq.support.isDev) {
                        //lq.console.log(['Update menu error', arguments, xhr.getAllResponseHeaders()]);
                    	console.log(['Update menu error', arguments, xhr.getAllResponseHeaders()]);
                    }
                    //lq.console.log('displayreeor from error:');
                    console.log('displayreeor from error:');
                    
                    //Pour déterminer l'onglet par défaut selon la page visitée
                    var adresse = window.location.pathname.split("/");
                    //Au cas où la page est suivie d'un .html
                    var page = adresse[adresse.length - 1].split(".")[0];
                    var boutonMenu = $('#lqBoutonZoneMenuComplet');
                    boutonMenu.click(function() {
                        if (typeof connexionLq !== "undefined" && connexionLq !== null){
                			connexionLq.gestionMenuJouerEnLigne();
                		}
                        toggleMenuDisplay(boutonMenu);
                    });
                    $('.lqZoneMenuOngletNiveau1').each(function() {
                        var onglet = $(this);
                        var ongletName = $("a", this).attr("href").split("/")[2];
                        var ongletId = $("a", this).attr("id");
                        
                        //On doit vérifier si le lien de l'onglet apparait dans l'url complete ou verifier la page parent.
                        //if ($("a", this).attr("href").includes(ongletName)) {
                    	if (window.location.pathname.includes(ongletName)) {
                            onglet.addClass("ongletParDefault");
                            afficherSousMenuGeneral(ongletId);
                            $("#LiensMenuGeneral_" + ongletId + " .lqZoneLienOnglet .lqZoneMenuLienInterne").each(function() {                          
                                if ($(this).attr("href").includes(page) && ongletName != page) {
                                	$(this).addClass('pageActuelle');
                                }
                            });
                        } else {
                            onglet.removeClass("ongletParDefault");
                        }
                        
                        
                        // Corriger erreur d'accessibilité (HTML Code Sniffer)
                        $('.lqFlecheSubMenu').html("<span class='sr-only'>bouton</span>");

                        //Permettre d'afficher un sous-menu lorsqu'on clique le bouton du hamburger menu
                        $('.lqFlecheSubMenu', this).click(function() {
                            toggleTabDisplay(onglet);
                        });
                    });
                    
                    
                }
            };
			$.ajax(options);
		}
		return{
	      initResult: function initResult() {
	          getMenu();
	          setTimeout(function(){

	            if (typeof connexionLq !== "undefined" && connexionLq !== null) {
	              connexionLq.gestionMenuJouerEnLigne();
	            }

	            }, 1000);

	        }
        };
    })();
    // Update global scope lq namespace
    window.lq = lq;

    //--> Auto trigger all self implemented functionalities on document ready
    lq.load.push(
        "lq.menu", [
            'initResult'
        ]
    );

}($, document, window, (window.lq || {})));
