/*! LQ NameSpace | Copyright 2015 Loto Quebec Inc. */
/*global jQuery,lq,console*/
/*jshint -W030*/
;
(function($, document, window, lq) {
    // 'use strict';

    // Define or extend barre sub-namespace.
    lq.barre = $.extend((lq.barre || {}), {

        /**
         * Handle l'affichage de la barre de notification.
         * Apply to all element with a class "notificationContainer et fermerNotification"
         * @function handleBarreNotification
         * @memberOf lq.nav
         **/
        handleBarreNotification: function() {
            var $fermerNotification = $('#fermerNotification'),
                $notificationContainer = $('#notificationContainer'),
                activeClassName = 'active',
                cookieName = 'barreNotificationFermer',
                nbJourExpirationAlert = 15,
                isCookieSet = lq.cookies.get(cookieName);

            if (!isCookieSet || ($("#notificationContainer > div").hasClass('alert-warning'))) {
                $notificationContainer.addClass("active");
            }

            var cacherBarre = function() {

                const domaineActuel = window.location.hostname;
                $notificationContainer.removeClass("active");

                // On obtient le nom de domaine en cours
                let urlAutreDomaine = window.location.hostname;
                const loteriesLotoquebec = '.lotoquebec';
                const loteriesEspacejeux = '.espacejeux';

                // On transforme le nom de domaine afin d'obtenir le nom de domaine "gentil' ou "méchant"
                if (urlAutreDomaine.indexOf(loteriesLotoquebec) !== -1) {
                    urlAutreDomaine = urlAutreDomaine.replace(loteriesLotoquebec, loteriesEspacejeux);
                } else {
                    urlAutreDomaine = urlAutreDomaine.replace(loteriesEspacejeux, loteriesLotoquebec);
                }

                const autreDomaine = urlAutreDomaine;

                let html;
                if (window.location.pathname.indexOf('magnoliaAuthor/') >= 0) {
                    lq.cookies.set(cookieName, "true", {
                        path: '/',
                        expires: nbJourExpirationAlert,
                    });

                } else {
                    html = '';
                    html = `${html}<img src="https://${domaineActuel}/loteries-endpoints/cookie-cross-domain?domaine=${domaineActuel}&nom=barreNotificationFermer&valeur=${window.location.pathname}&duree=${nbJourExpirationAlert * 24 * 60 * 60}" />`;
                    html = `${html}<img src="https://${autreDomaine}/loteries-endpoints/cookie-cross-domain?domaine=${autreDomaine}&nom=barreNotificationFermer&valeur=${window.location.pathname}&duree=${nbJourExpirationAlert * 24 * 60 * 60}" />`;

                    // On affiche un iframe qui permet d'ajouter un cookie dans l'autre domaine.
                    jQuery('#cookieAutreDomAlerte').show();
                    jQuery('#cookieAutreDomAlerte').html(html);
                }
            };

            $('#messageContainer').on('closed.bs.alert', function() {
                cacherBarre();
            })
        },

    });

    // Update global scope lq namespace
    window.lq = lq;

    // Auto trigger all self implemented (plugin) functionalities
    lq.load.push(
        "lq.barre", [
            'handleBarreNotification'
        ]
    );

}(jQuery, document, window, (window.lq || {})));