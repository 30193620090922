/*! LQ NameSpace | Copyright 2015 Loto Quebec Inc. */
/*global jQuery,lq,console*/
/*jshint -W030*/
(function($, document, window, lq) {
    'use strict';
    /**
     * Marger 
     **/
    /*
        $(window).on("resize", recharge);

        function recharge() {
            if (window.location.href.indexOf("magnoliaAuthor") > 0) {
                location.reload();
            }
        }
    */
    //        console.log("Ça marche!");
    $('.marger').each(function() {
        var marger = $(this);
        if ($(window).width() < 768) { // format mobile
            if (marger.attr('data-marge-mobile') != null) { SetMarge(marger, marger.attr('data-marge-mobile').split(";")); }
        } else if ($(window).width() < 1200) { // format tablet
            if (marger.attr('data-marge-tablet') != null) { SetMarge(marger, ((marger.attr('data-marge-tablet'))).split(";")); }
        } else { //Desktop
            if (marger.attr('data-marge-desktop') != null) { SetMarge(marger, ((marger.attr('data-marge-desktop'))).split(";")); }
        }
    });

    function SetMarge(obj, marges) {
        if (marges == null) {
            return false;
        }
        marges.pop();
        marges.forEach(function(marge) {
            let margeCss = [];
            margeCss.push(marge.split(":"));
            obj.css(margeCss[0][0], margeCss[0][1]);
        });
    }


    // Update global scope lq namespace
    window.lq = lq;
    // Auto trigger all self implemented (plugin) functionalities

}(jQuery, document, window, (window.lq || {})));