(function($, document, window, lq) {
    //'use strict';

    lq.avisgroslots = (function() {

        //--> Self implemented functionalities

        /**
         * Functionality to request and update information about Jackpots.
         * Use class "getGrosLotsLoteries" to implement this functionality.
         * @returns {void}
         * @function getGrosLotsLoteries
         * @memberOf lq.avisgroslots
         */

        function getGrosLotsLoteries(scenario) {
            var servicesURI,
                suffixeLogo = '',
                prefixeURL = lq.configs.pathAssets + '/webresources/images/svg/'
            lang = lq.support.lang,
                dateDiv = '.date p',
                i18n = {
                    fr: {
                        weekdaysLong: ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'],
                        this: 'Ce',
                        tonight: 'Ce soir',
                        guaranteedMillion: 'Million garanti',
                        guaranteedMillionPlur: 'Millions garantis',
                        goldenBall: 'LA BOULE D&apos;OR',
                        of: 'de',
                        frenchDollar: '$',
                        lotClassique: 'LE CLASSIQUE',
                        prize: 'Lot',
                        error: 'Le montant du prochain gros lot est\xa0à\xa0venir.',
                        lotSupplementaireAlt: '+',
                        superTirageLogo: {
                            url: prefixeURL + 'ic_super_tirage' + suffixeLogo + '.svg',
                            alt: 'Super Tirage'
                        },
                        superTirageLogoPlus: {
                            url: prefixeURL + 'ic_super_tirage_plus' + suffixeLogo + '.svg',
                            alt: 'Plus Super Tirage'
                        },
                        bouleOrImg: {
                            url: lq.configs.pathAssets + '/webresources/images/' + 'ic_golden_ball.png',
                            alt: 'Boule d or'
                        }
                    },
                    en: {
                        weekdaysLong: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
                        this: 'This',
                        tonight: 'Tonight',
                        guaranteedMillion: 'Guaranteed Million',
                        guaranteedMillionPlur: 'Guaranteed Million',
                        goldenBall: 'THE GOLD BALL',
                        of: 'of',
                        frenchDollar: '',
                        lotClassique: 'THE CLASSIC',
                        prize: 'Prize',
                        lotSupplementaireAlt: '+',
                        error: 'The amount of the next jackpot is coming.',
                        superTirageLogo: {
                            url: prefixeURL + 'ic_super_draw' + suffixeLogo + '.svg',
                            alt: 'Super Draw'
                        },
                        superTirageLogoPlus: {
                            url: prefixeURL + 'ic_super_draw_plus' + suffixeLogo + '.svg',
                            alt: 'Plus Super Draw'
                        },
                        bouleOrImg: {
                            url: lq.configs.pathAssets + '/webresources/images/' + 'ic_golden_ball.png',
                            alt: 'The gold ball'
                        }
                    },

                    get: function(key) {
                        return (key in i18n[lang]) ? i18n[lang][key] : key;
                    }
                };

            servicesURI = lq.configs.srvDomain + '/loteries-endpoints/avis/groslots';
            if (scenario !== "") {
                servicesURI = servicesURI + '/' + scenario;
            }

            var updateJackpots = function() {

                var options = {
                    'type': 'GET',
                    'url': servicesURI,
                    'dataType': 'json',
                    'cache': true,
                    'success': function(data) {
                    	if ($.trim(data) == undefined || $.trim(data) == null || $.trim(data) == "" ){
	                		console.log('displayreeor from error:');
	                        displayError(212);
	                        displayError(223);
	                	} else {
	                		doUpdate(data);
	                	}
                    },
                    'error': function(xhr, status, errMsg) {
                        // Si en dev, output un message d'erreurs, les paramètre et les headers reçus.
                        if (lq.support.isDev) {
                            console.log(['Update avis-groslots error', arguments, xhr.getAllResponseHeaders()]);
                        }
                        console.log('displayreeor from error:');
                        displayError(212);
                        displayError(223);
                    }
                };

                var displayError = function(prod) {
                    var $prodDiv = $('.prod' + prod);
                    $prodDiv.find(dateDiv).html(i18n.get('error'));
                    $prodDiv.find('.groslot-info-wrap').css('display', 'none');
                    $prodDiv.find('.groslot-approx').css('display', 'none');
                    $('.prod' + prod).addClass("erreur");
                };

                var doUpdate = function(data) {

                    var expectedLotteries = [212, 223], // liste des # de produit qu'on recherche dans le feed
                        todayDate,
                        prodElement,
                        $prodDiv,
                        $movedDiv,
                        dateTirage,
                        isToday,
                        aujourdhuiTxt,
                        thedayTxt,
                        thedateTxt,
                        chaineDate,
                        prochainGaranti,
                        prochainGrosLot,
                        isSingular,
                        approx,
                        chaineHTMLLots;

                    try {

                        // Pour la "date du jour" on se fie sur ce qui nous est retourné dans le feed
                        todayDate = new Date(data.dateCourante);

                        $.each(data.resultatsDesLoteries, function(index, element) {

                            $.each(element, function(index, element) {

                                prodElement = element.noProduit;
                                $prodDiv = $('.prod' + prodElement);
                                expectedLotteries.splice(expectedLotteries.indexOf(prodElement), 1);

                                try {

                                    $.each(element.tirage, function(index, element) {

                                        if (element.enBlackout == 0) {
                                            dateTirage = new Date(element.dateProchainTirage);
                                            isToday = (dateTirage.getMonth() + '-' + dateTirage.getDate() + '-' + dateTirage.getFullYear() === todayDate.getMonth() + '-' + todayDate.getDate() + '-' + todayDate.getFullYear());

                                            aujourdhuiTxt = i18n.get('tonight');
                                            thedayTxt = i18n.get('weekdaysLong')[dateTirage.getDay()];
                                            thedayTxt = i18n.get('this') + ' ' + thedayTxt;

                                            let lotsGarantisAutre = false;

                                            thedateTxt = (lang === 'fr') ?
                                                '<span class="strong">' + (dateTirage.getDate() === 1 ? dateTirage.getDate() + '<sup>er</sup>' : dateTirage.getDate()) + '&nbsp;' + lq.i18n.get('monthsFull')[dateTirage.getMonth()] + '&nbsp;' + dateTirage.getFullYear() + '</span>' :
                                                '<span class="strong">' + lq.i18n.get('monthsFull')[dateTirage.getMonth()] + '&nbsp;' + dateTirage.getDate() + ',&nbsp;' + dateTirage.getFullYear() + '</span>';

                                            chaineDate = isToday ? aujourdhuiTxt : thedayTxt + ' ' + thedateTxt;
                                            $prodDiv.find(dateDiv).html(chaineDate);

                                            // 649
                                            if (prodElement == 212) {

                                                if (element.prochainGrosLotTirageClassique != undefined && element.prochainGrosLotTirageClassique !=null &&
	                                                element.prochainGrosLotTirageClassique != "" && element.prochainGrosLotTirageClassique > 0 ) {
													chaineHTMLLots = '<div class="classique"><span class="texteClassique">'+i18n.get('lotClassique')+'</span><span class="montantClassique"> ' + ((element.prochainGrosLotTirageClassique / 1000000)+"").replace('.',',') + ' Million' + i18n.get('frenchDollar') + '</span></div>';
													chaineHTMLLots += '<div class="bouleOr"><img class="bouleOrImg" src="' + i18n.get('bouleOrImg')['url'] + '" alt="' + i18n.get('bouleOrImg')['alt'] + '"/>';
													chaineHTMLLots += '<div class="lqZoneDetailBouleOr"><div class="texteBouleOr">'+i18n.get('goldenBall')+'</div><div class="pglBouleOr"><span class="prochainGrosLotBouleOr">'+((element.prochainGrosLotBouleOr  / 1000000)+"").replace('.',',') + ' Million' + i18n.get('frenchDollar') + '<sup>*</sup></span>';
													chaineHTMLLots += '</div></div></div>';
                                                    
                                                    //TODO: Valider les conditions pour afficher "Super Tirage"
	                                                if (element.nbProchainLotsGarantis1M > 1 && element.nbProchainLotsGarantisAutre === 0) {
	                                                    $(".prod212 .superTirage").show();
                                                        //chaineHTMLLots = '<div class="float-left">' + chaineHTMLLots + '<p class="groslot-plus">' + prochainGaranti + '</p>' + '</div>';
	                                                    //chaineHTMLLots += '<div class="float-left"><p class="superTirage"><img src="' + i18n.get('superTirageLogo')['url'] + '" alt="' + i18n.get('superTirageLogo')['alt'] + '"></p></div>';
	                                                } else {
	                                                    if (element.nbProchainLotsGarantisAutre > 0) {
                                                            $(".prod212 .superTirage").show();
	                                                        //chaineHTMLLots = '<div class="float-left">' + chaineHTMLLots + '<p class="groslot-plus">' + prochainGaranti + '</p>' + '</div>';
	                                                        //chaineHTMLLots += '<div class="float-left"><p class="superTirage"><img src="' + i18n.get('superTirageLogoPlus')['url'] + '" alt="' + i18n.get('superTirageLogoPlus')['alt'] + '"></p></>';
	                                                    }
	                                                }

                                                }else{
	                                                isSingular = (element.nbProchainLotsGarantis1M == 1);
	                                                if(element.prochainGrosLotBouleOr != undefined && element.prochainGrosLotBouleOr !=null &&
	                                                element.prochainGrosLotBouleOr != ""){
														prochainGrosLot = element.prochainGrosLotBouleOr['value'];
													}else{
														prochainGrosLot = element.prochainGrosLot['value'];
													}
	                                                if (element.nbProchainLotsGarantis1M > 0) {
	                                                    chaineHTMLLots = '<p class="groslot-valeur">' + ((prochainGrosLot / 1000000)+"").replace('.',',') + ' Million' + i18n.get('frenchDollar') + '<sup>*</sup></p>';
	                                                    prochainGaranti = '<span>+</span> ' + element.nbProchainLotsGarantis1M;
	                                                    prochainGaranti += ' ' + (isSingular ? i18n.get('guaranteedMillion') : i18n.get('guaranteedMillionPlur'));
	                                                } else {
	                                                    chaineHTMLLots = '<p class="groslot-valeur">' + ((prochainGrosLot / 1000000)+"").replace('.',',') + ' Million' + i18n.get('frenchDollar') + '<sup>*</sup></p>';
	                                                }

	                                                if (element.nbProchainLotsGarantis1M > 1 && element.nbProchainLotsGarantisAutre === 0) {
	                                                    $(".prod212 .superTirage").show();
                                                        chaineHTMLLots = '<div class="float-left">' + chaineHTMLLots + '<p class="groslot-plus">' + prochainGaranti + '</p>' + '</div>';
	                                                    //chaineHTMLLots += '<div class="float-left"><p class="superTirage"><img src="' + i18n.get('superTirageLogo')['url'] + '" alt="' + i18n.get('superTirageLogo')['alt'] + '"></p></div>';
	                                                } else {
                                                        lotsGarantisAutre = true;
	                                                    if (element.nbProchainLotsGarantisAutre > 0) {
                                                            $(".prod212 .superTirage").show();
	                                                        chaineHTMLLots = '<div class="float-left">' + chaineHTMLLots + '<p class="groslot-plus">' + prochainGaranti + '</p>' + '</div>';
	                                                        //chaineHTMLLots += '<div class="float-left"><p class="superTirage"><img src="' + i18n.get('superTirageLogoPlus')['url'] + '" alt="' + i18n.get('superTirageLogoPlus')['alt'] + '"></p></>';
	                                                    } else {
	                                                        chaineHTMLLots += '<p class="groslot-plus">' + prochainGaranti + '</p>'
	                                                    }
	                                                }
                                                }



                                            } else {
                                                // LottoMax
                                                if ((element.nbProchainLotSupplementaire) && (element.nbProchainLotSupplementaire['value'] > 0)) {
                                                    chaineHTMLLots = '<p class="groslot-valeur">' + ((element.prochainGrosLot['value'] / 1000000)+"").replace('.',',') + ' Million' + i18n.get('frenchDollar') + '</p>';
                                                    approx = (element.nbProchainLotSupplementaire['approximatif'] === 1 ? true : false);
                                                    isSingular = (element.nbProchainLotSupplementaire['value'] === 1);
                                                    prochainGaranti = element.nbProchainLotSupplementaire['value'] + ' ';
                                                    prochainGaranti += i18n.get('prize') + (isSingular ? '' : 's') + ' ' + i18n.get('of') + ' 1 Million' + (approx ? '<sup>*</sup>' : '');
                                                    chaineHTMLLots += '<p class="groslot-plus"><span class="sr-only">+</span>' + prochainGaranti + '</p>'
                                                } else {
                                                    chaineHTMLLots = '<p class="groslot-valeur">' + ((element.prochainGrosLot['value'] / 1000000)+"").replace('.',',') + ' Million' + i18n.get('frenchDollar') + '<sup>*</sup></p>';
                                                }
                                            }


                                            $prodDiv.find(".groslot-info").html(chaineHTMLLots);

                                            if (lotsGarantisAutre) {
                                                $prodDiv.find(".groslot-info").addClass("lotsGarantisAutre");
                                            }

                                        } else {
                                            displayError(prodElement);
                                        }
                                        //faire un seul acces au dom
                                    });

                                } catch (e) {
                                    //lq.console.log('displayreeor catch 1:');
                                    console.log('displayreeor catch 1:' + e.message);
                                    displayError(element.noProduit);
                                }

                            });

                        });

                    } catch (e) {
                        //lq.console.log('displayreeor catch 2:');
                        console.log('displayreeor catch 2:');
                        displayError(212);
                        displayError(223);
                    }

                    // pour les loteries qui restent dans l'array, on affiche le message d'erreur
                    $.each(expectedLotteries, function(index, element) {
                        displayError(element);
                    });

                };

                $.ajax(options);
            };

            updateJackpots();

        }
        // Accès public aux méthodes
        return {
            avisgroslots: function() {
                if ($('.avis-groslots').length > 0) {
                    scenario = lq.commun.getParameterByName("s", window.location.href); //RR
                    getGrosLotsLoteries(scenario);
                }
            }

        };


    })();
    // Update global scope lq namespace
    window.lq = lq;

    //--> Auto trigger all self implemented functionalities on document ready
    lq.load.push(
        "lq.avisgroslots", [
            'avisgroslots'
        ]
    );

}($, document, window, (window.lq || {})));