(function($, document, window, lq) {

    lq.avislotsprogressifs = (function() {
        function displayProgressiveJackpot(noProduit, lotProgressif) {
            var lang = lq.support.lang,
                contenuHTML = '',
                contenuDivLogoProduit = $('.lots-progressifs .logoProduit').html(),
                dateTraduite = '',
                montantFormate = '',
                productLink,
                boutonJouer,
                boutonJouerSrOnly,
                zoneLP = $('#zoneLP'),
                lien = zoneLP.attr("data-lien")

            lq.commun.getProductMap(noProduit).then(() => {
                productLink = (lien != "" && lien != null) ? lien : lq.commun.getProductPage(noProduit);
                boutonJouer = lq.commun.getProductPlayButtonLabel(noProduit);
                boutonJouerSrOnly = lq.commun.getProductName(noProduit);
                zoneLP.removeAttr("data-lien");

                Promise.all([productLink, boutonJouer, boutonJouerSrOnly]).then((values) => {
                    const [productLink, boutonJouer, boutonJouerSrOnly] = values;

                    contenuHTML += '<a href="';
                    contenuHTML += productLink + '"><div class="lots-progressifs media"><div class="col-4 align-self-center">' + contenuDivLogoProduit + '</div>';
                    contenuHTML += '<div class="infoProgressif col-8 align-self-center"><div class="titreProgressif row">' + titreProgressif + '</div><div class="dateProgressif pt-3 pt-lg-1 pt-xl-1 row">';

                    if (lotProgressif && !lotProgressif.refus && lq.i18n.obtenirDateDuString(lotProgressif.date) !== "" && lq.i18n.obtenirMontantFormate(lotProgressif.montant) !== "") {
                        dateTraduite = lq.i18n.obtenirDateHeureFormatee(lq.i18n.obtenirDateDuString(lotProgressif.date)), montantFormate = lq.i18n.obtenirMontantFormate(lotProgressif.montant);
                        contenuHTML += '<p class="margeDate">' + dateTraduite + '</p></div>';
                        contenuHTML += '<div class="row justify-content-between"><div class="montantProgressif">' + montantFormate + '</div>';
                        contenuHTML += '<div class="progressif-approx"><p><p aria-hidden="true">Approx.</p><p class="sr-only">' + approximatifProgressif + '</p></div></div>';
                    } else {
                        if (lang == 'en') {
                            contenuHTML += '<p class="margeMsgLong">' + erreurProgressif + '</p></div>';
                        } else {
                            contenuHTML += '<p class="margeMsg">' + erreurProgressif + '</p></div>';
                        }
                    }
                    if (productLink && boutonJouer) {
                        contenuHTML += '<div class="zoneBouton row justify-content-between">';
                        contenuHTML += '<button class="btn btn-outline-primary opaque texte-bouton margeTopBouton">' + boutonJouer;
                        contenuHTML += '<span class="sr-only">&nbsp;' + boutonJouerSrOnly + '</span></button>';
                    }

                    contenuHTML += '</div></div></div>';

                    /* format xxs */

                    contenuHTML += '<div class="lots-progressifs col-12 media-xxs"><div class="align-self-start">' + contenuDivLogoProduit + '</div>';
                    contenuHTML += '<div class="infoProgressif align-self-center"><div class="titreProgressif">' + titreProgressif + '</div><div class="dateProgressif pt-3 pt-lg-1 pt-xl-1">';

                    if (lotProgressif && !lotProgressif.refus && lq.i18n.obtenirDateDuString(lotProgressif.date) !== "" && lq.i18n.obtenirMontantFormate(lotProgressif.montant) !== "") {
                        dateTraduite = lq.i18n.obtenirDateHeureFormatee(lq.i18n.obtenirDateDuString(lotProgressif.date)), montantFormate = lq.i18n.obtenirMontantFormate(lotProgressif.montant);
                        contenuHTML += '<p class="margeDate">' + dateTraduite + '</p></div></div>';
                        contenuHTML += '<div class="infoProgressif2"><div class="row justify-content-center"><div class="montantProgressif">' + montantFormate + '</div>';
                        contenuHTML += '<div class="progressif-approx"><p><p aria-hidden="true">Approx.</p><p class="sr-only">' + approximatifProgressif + '</p></div></div>';
                    } else {
                        contenuHTML += '<p class="infoProgressif2">' + '</div>';
                        if (lang == 'en') {
                            contenuHTML += '<p class="margeMsgLong">' + erreurProgressif + '</p></div>';
                        } else {
                            contenuHTML += '<p class="margeMsg">' + erreurProgressif + '</p></div>';
                        }
                    }

                    if (productLink && boutonJouer) {
                        contenuHTML += '<div class="zoneBouton row justify-content-center">';
                        contenuHTML += '<button class="btn btn-outline-primary opaque texte-bouton margeTopBouton">' + boutonJouer;
                        contenuHTML += '<span class="sr-only">&nbsp;' + boutonJouerSrOnly + '</span></button>';
                    }

                    contenuHTML += '</div></div></div>';
                    contenuHTML += '</a>';

                    /*    $('.contenantLots-progressifs').html(contenuHTML); */
                    $('div#zoneLP').html(contenuHTML);
                });
            });
        }

        function getResults(scenario) {
            var servicesURI = lq.configs.srvDomain + '/loteries-endpoints/lotprogressifpoker';
            if (scenario !== "") {
                servicesURI = servicesURI + '/' + scenario;
            }
            var updateGrosLosProgressif = function() {
                options = {
                    'type': 'GET',
                    'url': servicesURI,
                    'dataType': 'json',
                    'cache': true,
                    'success': function(data) {
                        if ($.trim(data) == undefined || $.trim(data) == null || $.trim(data) == "") {
                            console.log('displayreeor from error:');
                            displayProgressiveJackpot(noProduit, null);
                        } else {
                            displayProgressiveJackpot(noProduit, data.lotProgressif);
                        }
                    },
                    'error': function(xhr, status, errMsg) {
                        // Si en dev, output un message d'erreurs, les paramètres et les headers reçus.
                        if (lq.support.isDev) {
                            //lq.console.log(['Update lots progressifs error', arguments, xhr.getAllResponseHeaders()]);
                            console.log(['Update lots progressifs error', arguments, xhr.getAllResponseHeaders()]);
                        }
                        //lq.console.log('displayreeor from error:');
                        console.log('displayreeor from error:');
                        displayProgressiveJackpot(noProduit, null);
                    }
                };
                $.ajax(options);
            }
            updateGrosLosProgressif();
        }

        // Accès public aux méthodes
        return {
            afficherLotsProgressifs: function() {
                if ($('.lots-progressifs').length > 0) {
                    scenario = lq.commun.getParameterByName("s", window.location.href); //RR
                    getResults(scenario);
                }
            }
        };
    })();
    // Update global scope lq namespace
    window.lq = lq;

    //--> Auto trigger all self implemented functionalities on document ready
    lq.load.push(
        "lq.avislotsprogressifs", [
            'afficherLotsProgressifs'
        ]
    );

}($, document, window, (window.lq || {})));