/*! LQ NameSpace | Copyright 2015 Loto Quebec Inc. */
/*global jQuery,lq,console*/
/*jshint -W030*/
(function($, document, window, lq) {
    'use strict';
    /**
     * Conteneur-video 
     **/
    //console.log("Ça marche video!");

    if ($(window).width() < 768) { // format mobile
        var formatVideo = $('div.content-video .embed-responsive-9by16');
        if (formatVideo.length > 0) {
            formatVideo.each(function() {
                $(this).parent().css("margin-left", "-32px");
                if ($(window).width() < 576) {
                    $(this).parent().css("width", "220vw");
                } else if ($(window).width() < 768) {
                    $(this).parent().css("width", "221vw");
                }

            });
        }
    }

    window.lq = lq;
    // Auto trigger all self implemented (plugin) functionalities

}(jQuery, document, window, (window.lq || {})));