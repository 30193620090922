/*! LQ NameSpace | Copyright 2015 Loto Quebec Inc. */
/*global jQuery,lq,console*/
/*jshint -W030*/
;
(function($, document, window, lq) {
    'use strict';

    $('.media-bouton').each(function() {
        var bouton = $(this);
        /*
                if (bouton.data('buttoncolor')) {
                    var color = bouton.data('buttoncolor');

                    // https://api.jquery.com/css/#css-properties
                    $('.btnContainer', bouton).css("background-color", color);
                }
        */
        if (bouton.data('shadowcolor')) {
            var shadowcolor = bouton.data('shadowcolor');
            $('.btnContainer', bouton).css("box-shadow", "0 0 13.9px 2.1px " + shadowcolor);
        }
    });







    /*
        $('.media-bouton').each(function() {
            var bouton = $(this);

            var primaryColor = bouton.data('buttonprimarycolor');
            var secondaryColor = bouton.data('buttonsecondarycolor');

            if (primaryColor && secondaryColor) {
                $('.btnContainer', this).attr('style', 'background-image: linear-gradient(140deg, ' + primaryColor + ', ' + secondaryColor + ');');
            } else if (primaryColor) {
                $('.btnContainer', this).attr('style', 'background-color: ' + primaryColor + ';');
            }


            $('.btn', this).on("focus mouseenter", function() {
                $(this).css({
                    "background-color": primaryColor || secondaryColor || 'white',
                    "background-clip": "border-box"
                });
            });

            $('.btn', this).on("focusout mouseleave", function() {
                $(this).css({ "background-color": "transparent" });
            });


        });
    */

    /*
        $('.entete .shape-wrapper div.btn-container').each(function() {
            var monBouton = $(this);

            $(monBouton).addClass('shape');
        });
    */


    // Update global scope lq namespace
    window.lq = lq;

    // Auto trigger all self implemented (plugin) functionalities
    lq.load.push(
        "lq.utils");

}(jQuery, document, window, (window.lq || {})));