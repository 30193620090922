/*! LQ NameSpace | Copyright 2015 Loto Quebec Inc. */
(function($, document, window, lq) {
    const $moncarousel = $('.fn-carousel'),
        animIframes = document.getElementsByClassName("iframe-animation");
    let hasTranslated = false,
        attrSrc = '';

    const iterateIframes = animIframes => {
        Array.from(animIframes).forEach(element => {
            attrSrc = (element.closest(".owl-item.active") === null ? '' : element.getAttribute('data-src'));
            element.setAttribute('src', attrSrc);
        });
    }

    $moncarousel.each(() => {
        if (animIframes.length > 0) {
            $(this).on('initialized.owl.carousel', () => {
                iterateIframes(animIframes);
            });
            $(this).on('translated.owl.carousel', () => {
                hasTranslated ? '' : iterateIframes(animIframes);
                hasTranslated = true;
            });
            $(this).on('translate.owl.carousel', () => {
                hasTranslated = false;
            });
        }
    });
}($, document, window, (window.lq || {})));