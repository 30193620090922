/*! LQ NameSpace | Copyright 2015 Loto Quebec Inc. */
/*global jQuery,lq,console*/
/*jshint -W030*/

(function($, document, window, lq) {
    'use strict'
    /**
     * Conteneur-promotions
     **/
    //console.log("Ça marche promotions!");
    lq.promotions = (function() {
        // Accès public aux méthodes
        return {
            togglet: function(obj) {
                const lang = lq.support.lang;
                var arrowUp = String.fromCharCode(5169);
                var arrowDown = String.fromCharCode(5167);
                var hauteur = "0";
                if ($(window).width() < 321) {
                    hauteur = "120px";
                } else if ($(window).width() < 576) {
                    hauteur = "160px";
                } else if ($(window).width() < 768) {
                    hauteur = "135px";
                } else if ($(window).width() < 1200) {
                    hauteur = "180px";
                } else if ($(window).width() < 1460) {
                    hauteur = "370px";
                } else {
                    hauteur = "310px";
                }
                if ($(obj).text().indexOf("Fermer") >= 0 || $(obj).text().indexOf("Close") >= 0) {
                    var libel = $(obj).next().text();
                    $(obj).html(libel + " " + "<span id = 'fleche' ></span>");
                    $(obj).find("#fleche").text(arrowDown);
                    $(obj).closest(".card").removeAttr("style");
                    $(obj).closest(".card-body").css("height", hauteur);
                    $(obj).prev().hide();
                } else {
                    if (lang == "fr") {
                        $(obj).html("Fermer " + "<span id='fleche'></span>");
                        $(obj).find("#fleche").text(arrowUp);
                    } else {
                        $(obj).html("Close " + "<span id='fleche'></span>");
                        $(obj).find("#fleche").text(arrowUp);
                    }
                    $(obj).closest(".card").css("height", "auto");
                    $(obj).closest(".card-body").css("height", "auto");;
                    $(obj).prev().show();
                }
            },
            plusDePromo: function() {
                var nbPromo = parseInt($('#nbpromo').text());
                var numPromoCol = parseInt($('#numPromoCol').text());
                if (numPromoCol == null || numPromoCol == "" || isNaN(numPromoCol)) {
                    numPromoCol = 0;
                }
                if ($(".promotions .conteneur-flex-1-colonnes").css("display") != 'none') {
                    numPromoCol++;
                    if ((numPromoCol * 3) <= nbPromo || nbPromo % 3 != 0) {
                        $("#numPromoCol").text(numPromoCol);
                        $('.promotions .conteneur-flex-1-colonnes .conteneur-card-promo').each(function(index) {
                            var cp = $(this);
                            if (index < numPromoCol * 3) {
                                cp.css("display", "block");
                            } else {
                                cp.css("display", "none");
                            }
                        });
                    }
                    if ((numPromoCol * 3) >= nbPromo) {
                        $("#btn-pluspromo").css("display", "none");
                    }
                } else {
                    numPromoCol++;
                    if ((numPromoCol * 4 + 2) <= nbPromo || (nbPromo + 2) % 4 != 0) {
                        $("#numPromoCol").text(numPromoCol);
                        $('.promotions .conteneur-flex-2-colonnes .column').each(function(index) {
                            var col = $(this);
                            $(col).find('.conteneur-card-promo').each(function(index1) {
                                var cp = $(this);
                                if (index1 < numPromoCol * 2 + 1) {
                                    cp.css("display", "block");
                                } else {
                                    cp.css("display", "none");
                                }
                            });
                        });
                    }
                    if ((numPromoCol * 4 + 2) >= nbPromo) {
                        $("#btn-pluspromo").css("display", "none");
                    }
                }
            },
            plusDeGagnants: function() {
                var nbGagnants = parseInt($('#nbgagnants').text());
                var numGagnantsCol = parseInt($('#numGagnantsCol').text());
                if (numGagnantsCol == null || numGagnantsCol == "" || isNaN(numGagnantsCol)) {
                    numGagnantsCol = 0;
                }
                if ($(".gagnants .conteneur-flex-1-colonnes").css("display") != 'none') {
                    numGagnantsCol++;
                    if ((numGagnantsCol * 4 + 2) <= nbGagnants || (nbGagnants + 2) % 4 != 0) {
                        $("#numGagnantsCol").text(numGagnantsCol);
                        $(".gagnants .conteneur-flex-1-colonnes .conteneur-card-promo").each(function(index) {
                            var cp = $(this);
                            if (index < numGagnantsCol * 4 + 2) {
                                cp.css("display", "block");
                            } else {
                                cp.css("display", "none");
                            }
                        });
                    }
                    if ((numGagnantsCol * 4 + 2) >= nbGagnants) {
                        $("#btn-plusgagnants").css("display", "none");
                    }
                }
                if ($(".gagnants .conteneur-flex-2-colonnes").css("display") != 'none') {
                    numGagnantsCol++;
                    if ((numGagnantsCol * 4 + 2) <= nbGagnants || (nbGagnants + 2) % 4 != 0) {
                        $("#numGagnantsCol").text(numGagnantsCol);
                        $(".gagnants .conteneur-flex-2-colonnes .column").each(function(index) {
                            var col = $(this);
                            $(col).find(".conteneur-card-promo").each(function(index1) {
                                var cp = $(this);
                                if (index1 < numGagnantsCol * 2 + 1) {
                                    cp.css("display", "block");
                                } else {
                                    cp.css("display", "none");
                                }
                            });
                        });
                    }
                    if ((numGagnantsCol * 4 + 2) >= nbGagnants) {
                        $("#btn-plusgagnants").css("display", "none");
                    }
                }
                if ($(".gagnants .conteneur-flex-3-colonnes").css("display") != 'none') {
                    numGagnantsCol++;
                    if ((numGagnantsCol * 3 + 6) <= nbGagnants || (nbGagnants + 6) % 3 != 0) {
                        $("#numGagnantsCol").text(numGagnantsCol);
                        $(".gagnants .conteneur-flex-3-colonnes .column").each(function(index) {
                            var col = $(this);
                            $(col).find(".conteneur-card-promo").each(function(index1) {
                                var cp = $(this);
                                if (index1 < numGagnantsCol * 1 + 2) {
                                    cp.css("display", "block");
                                } else {
                                    cp.css("display", "none");
                                }
                            });
                        });
                    }
                    if ((numGagnantsCol * 3 + 6) >= nbGagnants) {
                        $("#btn-plusgagnants").css("display", "none");
                    }
                }
            }
        }
    })();

    var ancreOnglet = [],
        i = 0;
    $('div#conteneur-promo div.conteneur-onglets nav a.nav-item').each(function() {
        ancreOnglet[i++] = $(this);
    });

    var promo = null;
    var nbPromo = null;
    var gagnantsTab = null;
    var nbGagnants = null;
    if (ancreOnglet[0]) {
        promo = ancreOnglet[0];
        nbPromo = $('#nbpromo')
        if (nbPromo.text() > 0) {
            promo.text(promo.text() + ' (' + nbPromo.text() + ')')
        }
    }
    //    var gagnants = $('div.conteneur-onglets nav a[id="gagnants-tab GAGNANTS DES PROMOTIONS"]');
    if (ancreOnglet[1]) {
        gagnantsTab = ancreOnglet[1];
        nbGagnants = $('#nbgagnants')
        if (nbGagnants.text() > 0) {
            gagnantsTab.text(gagnantsTab.text() + ' (' + nbGagnants.text() + ')')
        }
    }
    /* correction du saut de l'ancre */
    var x = 0;

    var scrollToTarget = function() {
        $(window).scrollTop($("#conteneur-promo").offset().top);
    };

    var offsetApresChargement = function(el) {
        var rect = el.getBoundingClientRect();
        var scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
        var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
    };

    $("div#conteneur-promo .nav-item.nav-link.onglet-menu-item").on("click", function() {
        if (window.location.hash) {
            // handler is executed at most once
            $(window).one('scroll', scrollToTarget);
            if (x == 0) { setTimeout(function() { scrollToTarget(); }, 200); }
            x++;
        }
        if (x == 0) {
            setTimeout(function() { scrollToTarget(); }, 200);
            x++;
        }
    });

    /* lien quand un des onglets est vide */
    $(".col.promoVide p a").on("click", function() {
        $("div#conteneur-promo .nav-item.nav-link.onglet-menu-item").not(".active").trigger("click");
    });

    let hash = location.hash;

    if (ancreOnglet[1]) {
        lq.promotions.plusDePromo();
        let hashGagnants = ancreOnglet[1].attr("href") + "-";
        if (!hash.includes(hashGagnants)) {
            lq.promotions.plusDeGagnants();
        } else {
            $("#btn-plusgagnants").css("display", "none");
        }

        if (hash !== "") {
            if (hash.includes(gagnantsTab.attr("href"))) {
                promo.removeClass("active");
                $(promo.attr("href")).removeClass("show active");
                gagnantsTab.addClass("active");
                $(gagnantsTab.attr("href")).addClass("show active");

                if (hash.indexOf('-') != -1) {
                    let idPromo = "." + hash.substring(hash.indexOf('-') + 1);
                    if ($(idPromo).length != 0) {
                        $(idPromo).each(function() {
                            if ($(this).offset().top > 0) {
                                $(window).scrollTop($(this).offset().top);
                                $(this).find("#btn-gagnants").click();
                                return false;
                            }
                        });

                    } else {
                        $(window).scrollTop(gagnantsTab.offset().top);
                    }
                } else {
                    $(window).scrollTop(gagnantsTab.offset().top);
                }
            }
        }
    }

    // Update global scope lq namespace
    window.lq = lq
        // Auto trigger all self implemented (plugin) functionalities
})(jQuery, document, window, window.lq || {})