/*! LQ NameSpace | Copyright 2015 Loto Quebec Inc. */
/*global jQuery,lq,console*/
import numeral from 'numeral';
numeral.register('locale', 'fr', {
    delimiters: {
        thousands: ' ',
        decimal: ','
    },
    abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't'
    },
    ordinal: function(number) {
        return number === 1 ? 'er' : 'ème';
    },
    currency: {
        symbol: '$'
    }
});

(function($, document, window, lq) {
    'use strict';
    /**
     * Tableau     cmsfn.language()
     **/
    // check locale
    var lang = lq.support.lang;
    if (lang == 'en') {
        numeral.locale('en');
    } else {
        numeral.locale('fr');
    }
    $('.composante-tableau').each(function() {
        var tableau = $(this);
        //console.log($(tableau).find("th.tableau-titre").text());
        $("td, th", tableau).each(function() {
            if ($(this).attr("data-type") == "NUMERIQUE") {
                $(this).text(numeral($(this).text()).format('0,0'));
                $(this).css("white-space", "nowrap");
            } else if ($(this).attr("data-type") == "MONETAIRE_SANS_CENTS") {
                if (lang == 'en') {
                    $(this).text(numeral($(this).text()).format('$0,0[.]00'));
                } else {
                    $(this).text(numeral($(this).text()).format('0,0[.]00 $'));
                }
                $(this).css("white-space", "nowrap");
            } else if ($(this).attr("data-type") == "FRACTION_SANS_DECIMALE") {
                Fraction($(this), $(this).text().split("/"), '0,0', '0,0');
                $(this).css("white-space", "nowrap");
            } else if ($(this).attr("data-type") == "FRACTION_AVEC_UNE_DECIMALE") {
                Fraction($(this), $(this).text().split("/"), '0,0', '0,0.0');
                $(this).css("white-space", "nowrap");
            } else if ($(this).attr("data-type") == "FRACTION_AVEC_DEUX_DECIMALES") {
                Fraction($(this), $(this).text().split("/"), '0,0', '0,0.00');
                $(this).css("white-space", "nowrap");
            }
        });
        var trPrev = null;
        $("tbody tr", tableau).each(function(index) {
            var nb = $("tr", this).length;
            if ($(this).hasClass("sousTitre") || $(this).hasClass("entete") ||
                $(this).hasClass("total") || $(this, "th").hasClass("tableau-titre") ||
                $(this).hasClass("pale") || $(this).hasClass("fonce")) {
                trPrev = null;
            } else {
                if (trPrev != null) {
                    trPrev.addClass("border-bottom");
                }
                trPrev = $(this);
            }
        });
    });

    function Fraction(obj, nums, format1, format2) {
        if (nums == null) return 0;
        let q1 = numeral(nums[0]).format(format1);
        let s2 = "";
        if (lang == 'en') {
            s2 = nums[1].replace(",", ".");
        } else {
            s2 = nums[1];
        }
        let q2 = numeral(s2).format(format2);
        if (q2 == 0) q2 = 1;
        obj.text(q1 + ' / ' + q2);
    }
    // Update global scope lq namespace
    window.lq = lq;
    // Auto trigger all self implemented (plugin) functionalities

}(jQuery, document, window, (window.lq || {})));