/*
 * MasqueSaisie
 * Permet d'appliquer un masque de saisie à un champ texte
 * 
 * Utilisation :
 * 
 * var masqueBillet = new MasqueSaisie("****-***-***", "*", "ACEFGHJKLMNPQRSTUVWXY345679");
 * masqueBillet.addChamp($("#billet"));
 * 
 * var masqueTelephone = new MasqueSaisie("(XXX) XXX-XXXX", "X", "\\d");
 * masqueTelephone.addChamp($("#telephone"));
 * 
 */

/*
 * Constructeur du masque de saisie
 * 
 * format : Le masque, qui sera affiché dans le champ
 * symbole : Le caractère du format qui représente ce qui doit être saisi
 * caracteres : Liste des caractères acceptés (exprimé en expression régulière)
 */

(function($, document, window, lq) {
	'use strict';	

window.MasqueSaisie = function (format, symbole, caracteres) {
    this.format = format;

    this.symbole = "*";
    if (symbole != null) {
        if (symbole.length != 1) {
            console.log("ERREUR symbole invalide : " + symbole);
        } else {
            this.symbole = symbole;
        }
    }

    this.caracteres = "a-zA-Z0-9";
    if (caracteres != null) {
        this.caracteres = caracteres;
    }
}

MasqueSaisie.prototype.addChamp = function($champ) {
    return new ChampMasque($champ, this);
}

// ////

function ChampMasque($champ, masque) {
    this.texte = "";
    this.$champ = $champ;
    this.masque = masque;
    this.$superposeSaisiInvisible = null;
    this.$superposeMasque = null;

    var $wrap = $("<span></span>");
    $wrap.addClass("masque-coquille");
    $champ.wrap($wrap);

    var $superpose = $("<span></span>");
    $superpose.addClass("masque-superpose");
    $champ.before($superpose);

    this.$superposeSaisiInvisible = $("<span></span>");
    this.$superposeSaisiInvisible.addClass("invisible");
    $superpose.append(this.$superposeSaisiInvisible);

    this.$superposeMasque = $("<span></span>");
    this.$superposeMasque.addClass("masque");
    $superpose.append(this.$superposeMasque);

    var fontSize = $champ.css("font-size");
    var fontFamily = $champ.css("font-family");
    this.$superposeSaisiInvisible.css("font-size", fontSize);
    this.$superposeSaisiInvisible.css("font-family", fontFamily);
    this.$superposeMasque.css("font-size", fontSize);
    this.$superposeMasque.css("font-family", fontFamily);   

    this.$superposeMasque.text(this.masque.format);

    $champ.on("keyup change", {
        champMasque : this
    }, function(event) {
        event.data.champMasque.traiterChamp();
    });

    
    $champ.on("keydown", { 
        champMasque : this 
    }, function(event) { 
        // Si un caractère est saisi
        if (!event.ctrlKey && ((event.which >= 48 && event.which <= 90) || (event.which >= 96 && event.which <= 111) || (event.which >= 186 && event.which <= 222))) {
            if(event.data.champMasque.texte.length == event.data.champMasque.masque.format.length) {
                return false;
            }
            
            //TODO activer code en commentaire lors d'une modification subsequente
            //var c = event.data.champMasque.getChar(event);
            //c = c.toUpperCase();

            //var regex = new RegExp("[" + event.data.champMasque.masque.caracteres + "]");
        
            //if (!regex.test(c)) { 
            //    return false;
            //}
        }
        
        return true;
    });
    
    
    $champ.on("click", {
        champMasque : this
    }, function(event) {
        var pos = this.selectionStart;
        event.data.champMasque.curseurPos = pos;
    });
    
    this.traiterChamp();
}

ChampMasque.prototype.traiterChamp = function() {
    var texte = this.$champ.val();
    var curseurPos = this.$champ[0].selectionStart;
    var saisiMasque = this.appliquerMasque(texte);
    
    // Si le texte n'a pas changé
    if (texte == this.texte) {
        if (this.isAndroid()) {
            this.positionnerCurseur(this.curseurPos);
        }
        else{
            this.curseurPos = curseurPos;
        }
        return;
    }

    // Si le texte est plus long que le masque, annule la saisie
    if (texte.length > this.masque.format.length) {
        this.$champ.val(this.texte);
        this.positionnerCurseur(this.curseurPos);
        return;
    }

    // Si le texte brut a changé, mais pas après l'application du masque
    if (saisiMasque == this.texte) {

        if (texte.length < this.texte.length) {
            // Backspace - efface un espace - on efface le caractère précédent
            if (curseurPos < this.curseurPos) {
                this.backspaceEspace();
                return;
            }

            // Delete - efface un espace - on efface le caractère suivant
            if (curseurPos == this.curseurPos && this.$champ[0].selectionStart == this.$champ[0].selectionEnd) {
                this.deleteEspace();
                return;
            }
        } 
        else {
            this.$champ.val(this.texte);
            this.positionnerCurseur(this.curseurPos);
            return;
        }
    }

    if (saisiMasque.length > this.texte.length) {
        var nbCharAjouteParMasque = saisiMasque.length - texte.length;
        curseurPos += nbCharAjouteParMasque;
    }
    
    this.setTexte(saisiMasque);
    this.positionnerCurseur(curseurPos);
}


ChampMasque.prototype.setTexte = function(texte) {
    this.texte = texte;
    this.refresh();
}


// Retourne les caracteres saisis avec le masque appliqué
ChampMasque.prototype.appliquerMasque = function(chaine) {
    var chaine = chaine.toUpperCase();

    var regex = new RegExp("[^" + this.masque.caracteres + "]", "g");
    var valeur = chaine.replace(regex, "");

    var saisiMasque = "";
    var iChar = 0;
    var nbEspace = 0;
    while (iChar < this.masque.format.length
            && iChar < valeur.length + nbEspace) {
        var charFormat = this.masque.format.charAt(iChar);

        if (charFormat != this.masque.symbole) {
            saisiMasque += charFormat;
            nbEspace++;
        } else {
            var charSaisi = valeur.charAt(iChar - nbEspace);
            saisiMasque += charSaisi;
        }

        iChar++;
    }

    return saisiMasque;
}


ChampMasque.prototype.positionnerCurseur = function(pos) {
    this.$champ[0].selectionStart = pos;
    this.$champ[0].selectionEnd = pos;
    this.curseurPos = pos;
}


ChampMasque.prototype.backspaceEspace = function() {
    var texte = this.texte;
    var ok = false;
    var pos = this.curseurPos;
    
    while (!ok) {
        pos--;

        if (pos == 0) {
            ok = true;
        }
        else if (this.masque.format.charAt(pos) == this.masque.symbole) {
            ok = true;
        }
    }
    
    texte = texte.substr(0,pos) + texte.substr(pos+1);
    texte = this.appliquerMasque(texte);
    this.setTexte(texte);
    this.positionnerCurseur(pos);
}


ChampMasque.prototype.deleteEspace = function() {
    var texte = this.texte;
    var ok = false;
    var pos = this.curseurPos + 1;
    
    while (!ok) {
        if (this.masque.format.charAt(pos) == this.masque.symbole) {
            ok = true;
        }
        else {
            pos++;
        }
        
        if (pos == texte.length) {
            ok = true;
        }
    }
    
    texte = texte.substr(0,pos) + texte.substr(pos+1);
    texte = this.appliquerMasque(texte);
    this.setTexte(texte);
    this.positionnerCurseur(this.curseurPos);
}


ChampMasque.prototype.refresh = function() {
    var restantMasque = this.masque.format.substring(this.texte.length, this.masque.format.length);
    this.$champ.val(this.texte);
    this.$superposeSaisiInvisible.text(this.texte);
    this.$superposeMasque.text(restantMasque);
}


ChampMasque.prototype.reset = function() {
    this.curseurPos = 0;
    this.setTexte("");
}

ChampMasque.prototype.isAndroid = function () {
    return navigator && /android/i.test(navigator.userAgent);
}

ChampMasque.prototype.getChar = function(e) {
    /*** Convert to Char Code ***/
    var code = e.which;
	
    //Ignore Shift Key events & arrows
    var ignoredCodes = {
        16: true,
        37: true,
        38: true,
        39: true,
        40: true,
        20: true,
        17: true,
        18: true,
        91: true
    };

    if(ignoredCodes[code] === true) {
        return false;
    }

    //These are special cases that don't fit the ASCII mapping
    var exceptions = {
        186: 59, // ;
        187: 61, // =
        188: 44, // ,
        189: 45, // -
        190: 46, // .
        191: 47, // /
        192: 96, // `
        219: 91, // [
        220: 92, // \
        221: 93, // ]
        222: 39, // '
        //numeric keypad
        96: '0'.charCodeAt(0),
        97: '1'.charCodeAt(0),
        98: '2'.charCodeAt(0),
        99: '3'.charCodeAt(0),
        100: '4'.charCodeAt(0),
        101: '5'.charCodeAt(0),
        102: '6'.charCodeAt(0),
        103: '7'.charCodeAt(0),
        104: '8'.charCodeAt(0),
        105: '9'.charCodeAt(0)
    };

    if(exceptions[code] !== undefined) {
        code = exceptions[code];
    }

    var ch = String.fromCharCode(code);

    /*** Handle Shift ***/
    if(e.shiftKey) {
        var special = {
            1: '!',
            2: '@',
            3: '#',
            4: '$',
            5: '%',
            6: '^',
            7: '&',
            8: '*',
            9: '(',
            0: ')',
            ',': '<',
            '.': '>',
            '/': '?',
            ';': ':',
            "'": '"',
            '[': '{',
            ']': '}',
            '\\': '|',
            '`': '~',
            '-': '_',
            '=': '+'
        };

        if(special[ch] !== undefined) {
            ch = special[ch];
        }
    }
    else {
        ch = ch.toLowerCase();
    }

    return String.fromCharCode(ch.charCodeAt(0));
}
window.lq = lq;
}(jQuery, document, window, (window.lq || {})));