(function($, document, window, lq) {

    lq.commun = (function() {
        var productPageMap = {},
            productNameMap = {},
            productExpirationDateMap = {},
            productType = {},
            productPlayButtonLabelMap = {},
            productLogoMap = {},
            productIdMobileMap = {},
            productsMap = null;

        function createProductsMap() {
            return new Promise(function(resolve, reject) {
                var paramLangue = lq.support.lang + '-CA',
                servicesURI = lq.configs.internalSrvDomain + '/.rest/loteries/listerProduits?lang=' + paramLangue,
                options = {
                    'type': 'GET',
                    'url': servicesURI,
                    'dataType': 'json',
                    'cache': true,
                    'success': function(data) {
                        productsMap = {};
                        var results = data.results;
                        for (var i in results) {
                            productPageMap[results[i].noProduit] = results[i].pageProduit;
                            productNameMap[results[i].noProduit] = results[i].nomProduit;
                            productType[results[i].noProduit] = results[i].typeProduit;
                            productExpirationDateMap[results[i].noProduit] = results[i].dateRetrait;
                            productPlayButtonLabelMap[results[i].noProduit] = results[i].labelBoutonJouer;
                            productLogoMap[results[i].noProduit] = results[i].logoVbelProduit;
                            productIdMobileMap[results[i].noProduit] = results[i].idMobile;
                            productsMap[results[i].noProduit] = {
                                pageProduit: results[i] !== undefined ? results[i].pageProduit : null,
                                nomProduit: results[i] !== undefined ? results[i].nomProduit : null,
                                typeProduit: results[i] !== undefined ? results[i].typeProduit : null,
                                dateRetrait: results[i] !== undefined ? results[i].dateRetrait : null,
                                labelBoutonJouer: results[i] !== undefined ? results[i].labelBoutonJouer : null,
                                logoVbelProduit: results[i] !== undefined ? results[i].logoVbelProduit : null,
                                idMobile: results[i] !== undefined ? results[i].idMobile : null,
                            }
                        }
                        resolve(productsMap);
                    },
                    'error': function(xhr, status, errMsg) {
                        // Si en dev, output un message d'erreurs, les paramètres et les headers reçus.
                        if (lq.support.isDev) {
                            //lq.console.log(['Update createProductsMap error', arguments, xhr.getAllResponseHeaders()]);
                            console.log(['createProductsMap error', arguments, xhr.getAllResponseHeaders()]);
                        }
                        //lq.console.log('displayreeor from error:');
                        console.log('displayreeor from error:');
                        reject(errMsg)
                    }
                };

                productsMap === null && $.ajax(options);
                productsMap !== null && resolve(productsMap);
            });
        }

        function getProductMap(noProduit) {
            return new Promise((resolve) => {
                if (productsMap === null) {
                    createProductsMap().then((productsMap) => resolve(productsMap[noProduit]));
                }
                else {
                    resolve(productsMap[noProduit])
                }
            });
        }

        async function getProductsMap() {
            return createProductsMap();
        }

        // Accès public aux méthodes
        return {
            getProductsMap : getProductsMap,
            getProductMap: getProductMap,
            getProductPage: function(noProduit) {
                return new Promise(function(resolve){
                    getProductMap(noProduit).then(
                        (productMap) => (resolve(productMap.pageProduit))
                    )
                });
            },
            getProductName: function(noProduit) {
                return new Promise(function(resolve) {
                    getProductMap(noProduit).then(
                        (productMap) => (resolve(productMap.nomProduit))
                    )
                });
            },
            getProductType: function(noProduit) {
                return new Promise(function(resolve) {
                    getProductMap(noProduit).then(
                        (productMap) => (resolve(productMap.typeProduit))
                    )
                });
            },
            getProductExpirationDate: function(noProduit) {
                return new Promise(function(resolve){
                    getProductMap(noProduit).then(
                        (productMap) => (resolve(productMap.dateRetrait))
                    )
                });
            },
            getDate: function() {
                return new Promise (function(resolve) {
                    var dateRetour = null;
                    if (lq.support.isDev || lq.configs.devMode === 'oui') {
                        var servicesURI = lq.configs.srvDomain + '/loteries-endpoints/datelogique',
                            options = {
                                'type': 'GET',
                                'url': servicesURI,
                                'dataType': 'json',
                                'cache': true,
                                'success': function(data) {
                                    if (data.date !== null) {
                                        dateRetour = new Date(data.date);
                                    } else {
                                        dateRetour = new Date();
                                    }
                                    resolve(dateRetour)
                                },
                                'error': function(xhr, status, errMsg) {
                                    dateRetour = new Date();
                                    console.log(['Fetch date erreur', arguments, xhr.getAllResponseHeaders()]);
                                    resolve(dateRetour)
                                }
                            };
                        $.ajax(options);
                    } else {
                        dateRetour = new Date();
                        resolve(dateRetour);
                    }

                    //TODO
                    //dateRetour = new Date();
                    //return dateRetour;
                });
            },
            getProductPlayButtonLabel: function(noProduit) {
                return new Promise (function(resolve) {
                    getProductMap(noProduit).then(
                        (productMap) => (resolve(productMap.labelBoutonJouer))
                    )
                });
            },
            getProductVBEL: function(idMobile) {
                return new Promise (function(resolve) {
                    if (productsMap === null) {
	                    createProductsMap().then((productsMap) => resolve(
	                    Object.values(productsMap).filter((product) => product.idMobile == idMobile).shift()));
	                }
	                else {
	                    resolve(Object.values(productsMap).filter((product) => product.idMobile == idMobile).shift());
	                }
                });
            },
            getProductIdMobile: function(noProduit) {
                return new Promise (function(resolve) {
                    getProductMap(noProduit).then(
                        (productMap) => (resolve(productMap.idMobile))
                    )
                });
            },
            getParameterByName(name, href) {
                name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
                var regexS = "[\\?&]" + name + "=([^&#]*)";
                var regex = new RegExp(regexS);
                var results = regex.exec(href);
                if (results == null) {
                    return "";
                } else {
                    return decodeURIComponent(results[1].replace(/\+/g, " "));
                }
            }
        };
    })();
    // Update global scope lq namespace
    window.lq = lq;

}($, document, window, (window.lq || {})));